export default {
  /**
   * Update the query values in local state
   *
   * @param {Object} context
   * @param {Object} { key: String, value: String|Integer }
   */
  updateQuery({ commit }, { key, value }) {
    commit("UPDATE_QUERY", { key, value })
  },

  /**
   * Fetch unlocked insights from the API
   *
   * @param {Object} context
   */
  async fetchItems({ dispatch, commit, getters }) {
    // Create the loader
    const loaderId = Symbol()
    dispatch("loaders/add", loaderId, { root: true })

    try {
      const queryParams = new window.URLSearchParams(getters.query)

      const response = await axios({
        url: "/api/content-discovery-searches/?" + queryParams
      })

      // update the vuex state
      commit("UPDATE_RESPONSE", response.data)
    } catch (error) {
      // log using the helper function
      logger({ type: "Network Error", error })
    } finally {
      // close the loader
      dispatch("loaders/remove", loaderId, { root: true })
    }
  },

  /**
   * Make PUT request to update the saved search model
   *
   * @param {Object} context
   * @param {Object} payload
   */
  async updateItem({ dispatch }, { id, title, notes, sharingAccess }) {
    // Create the loader
    const loaderId = Symbol("savedSearches/contentDiscovery/actions/updateItem")
    dispatch("loaders/add", loaderId, { root: true })

    try {
      await axios({
        method: "PUT",
        url: `/api/content-discovery-searches/${id}`,
        data: { title, notes, sharing_access: sharingAccess }
      })

      // Refresh the list of saved searches
      dispatch("fetchItems")

      // Show the success message
      dispatch("toasts/add", { text: "Updated search record!" }, { root: true })
    } catch (error) {
      // log using the helper function
      logger({ type: "Network Error", error, comment: "Error while updating saved content discovery search" })

      // Show the error message
      dispatch("toasts/add", { text: "Error while updating saved search!" }, { root: true })
    } finally {
      // close the loader
      dispatch("loaders/remove", loaderId, { root: true })
    }
  },

  /**
   * Make DELETE request to delete the model
   *
   * @param {Object} context
   * @param {Object} payload
   */
  async deleteItem({ dispatch }, { id }) {
    // Create the loader
    const loader = Symbol("savedSearches/contentDiscovery/actions/deleteItem")
    dispatch("loaders/add", loader, { root: true })

    try {
      await axios({
        method: "DELETE",
        url: `/api/content-discovery-searches/${id}`
      })

      // Refresh the list of saved searches
      dispatch("fetchItems")

      // Show the success message
      dispatch("toasts/add", { text: "Deleted search record!" }, { root: true })
    } catch (error) {
      // log using the helper function
      logger({ type: "Network Error", error, comment: "Error while deleting saved content discovery search" })

      // Show the error message
      dispatch("toasts/add", { text: "Error while deleting saved search!" }, { root: true })
    } finally {
      // close the loader
      dispatch("loaders/remove", loader, { root: true })
    }
  }
}
