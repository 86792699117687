// Import the hosts value
import host from "@/json/host.js"

// Tells what the current viewport is
export default {
  computed: {
    /**
     * Get the current font style for the theme
     *
     * @returns {Object}
     */
    fontStyle() {
      return {
        fontFamily: host.theme.font || 'Public Sans'
      }
    },

    /**
     * Get environment variables
     *
     * @returns {Object}
     */
    env() {
      return this.$store.getters["config/env"]
    },

    /**
     * Get the host related data from this object
     *
     * @returns {Object}
     */
    host() {
      return host
    },

    /**
     * Get whether the current theme is dark or not
     *
     * @returns {Boolean}
     */
    isThemeDark() {
      return host.theme.type === "dark"
    },

    /**
     * Get whether the current theme is light or not
     *
     * @returns {Boolean}
     */
    isThemeLight() {
      return host.theme.type === "light"
    },

    /**
     * Whether or not the user is part of a team
     *
     * @returns {Boolean}
     */
    isPartOfTeam() {
      return this.$store.getters["auth/isPartOfTeam"]
    },

    /**
     * Get the sharing access hint
     *
     * @returns {String}
     */
    sharingAccessHint() {
      switch (this.form.sharingAccess) {
        case "self":
          return "Only you can access this report"
        case "team":
          return "Your team members can also view this report"
        case "public":
          return "Anyone with the link can view this report"
      }
    },

    /**
     * Get the sharing access options
     *
     * @returns {Array}
     */
    sharingAccessOptions() {
      return [
        {
          text: "Only me",
          value: "self",
          condition: true
        },
        {
          text: "My team",
          value: "team",
          condition: this.isPartOfTeam
        },
        {
          text: "Anyone with link",
          value: "public",
          condition: true
        }
      ].filter(option => option.condition).map(option => ({ text: option.text, value: option.value }))
    },

    /**
     * Get the createdBy options
     *
     * @returns {Array}
     */
    createdByOptions() {
      return [
        {
          text: "All",
          value: null,
        },
        {
          text: "Me",
          value: "self"
        },
        {
          text: "Team",
          value: "team"
        }
      ]
    }
  },

  methods: {
    /**
     * Return with encoded URL for an image
     *
     * @param {String} url
     * @param {Boolean} shouldEncode
     * @returns {String}
     */
    proxyUrl(url, shouldEncode = false) {
      return `${shouldEncode ? process.env.VUE_APP_IMAGE_PROXY_BASE + window.btoa(url) : url}${shouldEncode ? '&proxy=true' : ''}`
    },

    /**
     * Return the URL for a storage path
     *
     * @param {String} path
     * @returns {String}
     */
    storageUrl(path) {
      return `${process.env.VUE_APP_STORAGE_BASE}/${path}`
    }
  }
}
