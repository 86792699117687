export default {
  /**
   * Return the campaign table items
   *
   * @param {Object} state
   * @returns {Array}
   */
  items(state) {
    return state.items
  },

  /**
   * Return all filter objects that's been applied
   *
   * @param Object state
   * @returns Array
   */
  filters(state) {
    return state.filters
  },

  /**
   * Find the first object in applied filters list
   *
   * @param Object state
   * @returns Object
   */
  findFilterByType: (state) => (type) => {
    return state.filters.find((search) => search.type === type)
  },

  /**
   * Find the filters in applied filters list
   *
   * @param Object state
   * @returns Array
   */
  findFiltersByType: (state) => (type) => {
    return state.filters.filter((search) => search.type === type)
  },
}
