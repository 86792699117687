
// Import helper functions
import { generateRequestBody } from "./functions.js"
import { getClientID } from "@/helpers/clientHelper"
import { getValue } from "@/helpers/store.js"

export default {
  /**
   * Update the selected platform's value
   *
   * @param {Object} context
   * @param {String} payload
   */
  updateSelectedPlatform({ commit }, payload) {
    commit("UPDATE_SELECTED_PLATFORM", payload)
  },

  /**
   * Updates the sortBy filter
   * Could either be "followers" or "engagements"
   *
   * @param {Object} context
   * @param {String} payload
   */
  updateSortBy({ commit }, payload) {
    commit("UPDATE_SORT_BY", payload)

    // Trigger event for fetching data
    window.dispatchEvent(new CustomEvent('triggerSearch', {
      detail: {
        module: "influencerDiscovery",
        origin: "influencerDiscovery/updateSortBy"
      }
    }))
  },

  /**
   * Update the sort value
   *
   * @param {Object} context
   * @param {Object} payload
   * @returns {void}
   */
  updateSort({ commit }, payload) {
    commit("UPDATE_SORT", payload)
    // commit("UPDATE_SORT_BY", sortConverter(payload))
  },

  /**
   * Update the audience source
   *
   * @param {Object} context
   * @param {Object} payload
   * @returns {void}
   */
  updateAudienceSource({ commit }, payload) {
    commit("UPDATE_AUDIENCE_SOURCE", payload)
  },

  /**
   * Push an item to the filters array
   *
   * @param Object context
   * @param Object data
   */
  addFilter({ commit }, { type, data, sort = 10, isHidden = false, preventDelete = false }) {
    commit("ADD_FILTER_ITEM", {
      type,
      data,
      sort,
      isHidden,
      preventDelete,
      id: Symbol(),
      timestamp: Date.now()
    })

    // Trigger event to show the new filter
    window.dispatchEvent(new CustomEvent('addFilter', {
      detail: {
        module: "influencerDiscovery",
        origin: "influencerDiscovery/addFilter",
        item: arguments[1]
      }
    }))

    // Trigger event for fetching data
    // window.dispatchEvent(new CustomEvent('triggerSearch', {
    //   detail: {
    //     module: "influencerDiscovery",
    //     origin: "influencerDiscovery/addFilter"
    //   }
    // }))
  },

  /**
   * Find the item by the unique Symbol identifier and then delete it by splice method
   *
   * @param Object context
   * @param Object data
   */
  removeFilter({ commit, getters }, id) {
    const itemToDelete = getters.findFilterById(id)

    // If found, emit the window event
    if (itemToDelete) {
      // trigger event to let them know what was deleted
      window.dispatchEvent(new CustomEvent('removeFilter', {
        detail: {
          module: "influencerDiscovery",
          origin: "influencerDiscovery/removeFilter",
          item: itemToDelete
        }
      }))
    }

    commit("REMOVE_FILTER_ITEM", id)

    // Trigger event for fetching data
    // window.dispatchEvent(new CustomEvent('triggerSearch', {
    //   detail: {
    //     module: "influencerDiscovery",
    //     origin: "influencerDiscovery/removeFilter"
    //   }
    // }))
  },

  /**
   * Finds and removes all applied filters of a type
   *
   * @param {Object} context
   * @param {Object} data
   */
  removeFilters({ dispatch, getters }, type) {
    const items = getters.findFiltersByType(type)

    for (const item of items) {
      dispatch("removeFilter", item.id)
    }
  },

  /**
   * Find the filter item, delete it and then push a new item
   *
   * @param Object context
   * @param Object data
   */
  async replaceFilter({ getters, dispatch }, { type, data, sort = 10, isHidden = false, preventDelete = false }) {
    // Find the item and remove it
    const itemToDelete = getters.findFiltersByType(type).find((search) => search.data.kind === data.kind)
    if (itemToDelete) {
      await dispatch("removeFilter", itemToDelete.id)
    }

    // Add a new item for that type instead
    await dispatch("addFilter", { type, data, sort, isHidden, preventDelete })
  },

  /**
   * Find the filter item, update it's values
   *
   * @param Object context
   * @param Object data
   */
  updateFilter({ commit }, { id, data }) {
    commit("UPDATE_FILTER_ITEM", { id, data })

    window.dispatchEvent(new CustomEvent('updateFilter', {
      detail: {
        module: "influencerDiscovery",
        origin: "influencerDiscovery/updateFilter",
        item: arguments[1]
      }
    }))

    // Trigger event for fetching data
    // window.dispatchEvent(new CustomEvent('triggerSearch', {
    //   detail: {
    //     module: "influencerDiscovery",
    //     origin: "influencerDiscovery/updateFilter"
    //   }
    // }))
  },

  /**
   * Remove the action using filter name
   *
   * @param {Object} context
   * @param {Object} filter
   */
  removeAction({ dispatch, getters }, filter) {
    // Find the action
    const actions = getters.findFiltersByType("actions")

    // Find the index
    const action = actions.find((search) => search.data.inputs.filter === filter)

    // If found
    if (action) {
      // Remove it
      dispatch("removeFilter", action.id)
    }
  },

  /**
   * Add or update the action object
   *
   * @param {Object} context
   * @param {Object} props
   */
  replaceAction({ dispatch }, { filter, action }) {
    // Remove the value
    dispatch("removeAction", filter)

    // Add the action
    dispatch("addFilter", {
      type: "actions",
      isHidden: true,
      data: {
        inputs: { filter, action }
      }
    })
  },

  /**
   * Whenever platform is switched, reset the filters array
   *
   * @param {Object} context
   * @param {Boolean} shouldTriggerSearch
   * @returns {void}
   */
  resetFilters({ commit }, shouldTriggerSearch = true) {
    // Find the item and remove it
    commit("RESET_FILTERS")

    window.dispatchEvent(new CustomEvent('resetFilters', {
      detail: {
        module: "influencerDiscovery",
        origin: "influencerDiscovery/resetFilters"
      }
    }))

    // If we should trigger search
    if (shouldTriggerSearch) {
      // Trigger event for fetching data
      window.dispatchEvent(new CustomEvent('triggerSearch', {
        detail: {
          module: "influencerDiscovery",
          origin: "influencerDiscovery/resetFilters"
        }
      }))
    }
  },

  /**
   * Fetch data from API
   *
   * @param {Object} context
   * @param {Object} platform | Could be "instagram", "youtube" or "tiktok"
   */
  async searchAccounts({ dispatch, getters }, { platform, signal, triggerId, page = 1 }) {
    // Create the loader
    const loaderId = Symbol()
    dispatch("loaders/add", loaderId, { root: true })

    // compute the request object
    // get the applied filters
    // const appliedFilters = {}
    const requestBody = generateRequestBody(getters)

    // Add trackers for download progress here
    requestBody.clientId = getClientID()
    requestBody.triggerId = triggerId

    // Set the pagination values
    requestBody.paging = {
      "skip": page === 1 ? 0 : (page - 2) * 10,
      "limit": page === 1 ? 10 : 20
    }

    try {
      await axios({
        url: `/api/influencers/${platform}`,
        method: "POST",
        data: requestBody,
        signal
      })
    } catch (error) {
      // log using the helper function
      logger({ type: "Network Error", error })
    } finally {
      // close the loader
      dispatch("loaders/remove", loaderId, { root: true })
    }
  },

  /**
   * Download the generated data from backend
   *
   * @param {Object} context
   * @param {Object} payload
   */
  async exportData({ getters, dispatch }, { platform, triggerId, selectedRows, exportLimit = null, exportTitle = "", shouldIncludeContactDetails = false, shouldExcludePreviouslyExported = false }) {
    // Create the loader
    const loaderId = Symbol()
    dispatch("loaders/add", loaderId, { root: true })

    try {
      // Get the request body
      const requestBody = generateRequestBody(getters)

      // Set the pagination values
      requestBody.paging = {
        "skip": 0,
        "limit": exportLimit || getters.results(platform)?.accounts.length
      }

      // Compute the filename for the exported item
      const fromFollowers = getValue({ type: "followers", path: "from" }, getters.findFilterByType)
      const toFollowers = getValue({ type: "followers", path: "to" }, getters.findFilterByType)

      const filtersForTitleValues = [
        // Influencer Location
        getters.findFiltersByTypeAndKind("location", "influencer")
          .map((item) => item.data.inputs.name)
          .join(" / "),

        // Followers
        fromFollowers ? "From " + nFormatter(fromFollowers) : null,
        toFollowers ? "To " + nFormatter(toFollowers) : null,

        // Interests
        getters.findFiltersByTypeAndKind("interest", "influencer")
          .map((item) => item.data.inputs.name )
          .join(" / "),
      ]
      .filter(item => Boolean(item))
      .join(" - ")

      requestBody.exportTitle = exportTitle ? exportTitle : (filtersForTitleValues ? filtersForTitleValues : dayjs().format("LLL"))
      requestBody.selectedRows = selectedRows
      requestBody.exportLimit = exportLimit
      // requestBody.isMassDownload = (exportLimit === null ? false : true)
      requestBody.shouldIncludeContactDetails = shouldIncludeContactDetails
      requestBody.shouldExcludePreviouslyExported = shouldExcludePreviouslyExported

      requestBody.appliedFilters = getters.filters.sort((a, b) => a.sort - b.sort)

      // Add trackers for download progress here
      requestBody.clientId = getClientID()
      requestBody.triggerId = triggerId

      await axios({
        url: `/api/influencers/${platform}/export`,
        method: "POST",
        data: requestBody
      })

      dispatch("toasts/add", { text: "Please wait, this might take some time." }, { root: true })
    } catch (error) {
      // log using the helper function
      logger({ type: "Network Error", error })
    } finally {
      // close the loader
      dispatch("loaders/remove", loaderId, { root: true })
    }
  },
}
