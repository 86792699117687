export default {
  /**
   * Update the innerWidth property for config/responsiveness
   *
   * @param {Object} context
   */
  updateResponsivenessInnerWidth({ commit }) {
    commit("UPDATE_RESPONSIVENESS", { innerWidth: window.innerWidth })
  },

  /**
   * Update the configuration value for module
   *
   * @param {Object} context
   * @param {Object} payload
   */
  set({ commit }, payload) {
    commit("UPDATE_VALUE", payload)
  }
}
