export default {
  /**
   * Return the query object used when making requests
   *
   * @param {Object} state
   * @returns {Object}
   */
  query(state) {
    return state.query
  },

  /**
   * Return the API response for users endpoint
   *
   * @param {Object} state
   * @returns {Object|Null}
   */
  apiResponse(state) {
    return state.apiResponse
  },

  /**
   * Return the API response for credit usage endpoint
   *
   * @param {Object} state
   * @returns {Object|Null}
   */
  creditUsageResponse(state) {
    return state.creditUsageResponse
  },

  /**
   * Return all filter objects that's been applied
   *
   * @param Object state
   * @returns Array
   */
  filters(state) {
    return state.filters
  },

  /**
   * Search for filters based on their type, like "usernames" or "hashtags"
   *
   * @param Object state
   * @returns Array
   */
  findFiltersByType: (state) => (type) => {
    return state.filters.filter((search) => search.type === type)
  },

  /**
   * Find the first object in applied filters list
   *
   * @param Object state
   * @returns Object
   */
  findFilterByType: (state) => (type) => {
    return state.filters.find((search) => search.type === type)
  },

  /**
   * Find the unique filter object by Symbol as identifier
   *
   * @param Object state
   * @returns Object
   */
  findFilterById: (state) => (id) => {
    return state.filters.find((search) => search.id === id)
  }
}
