export default {
  /**
   * Whether or not to show the full screen loader
   *
   * @param {object} state
   * @returns {boolean}
   */
    isVisible(state) {
    return state.items.length !== 0
  }
}
