import dayjs from "./dayjs"

/**
 * Format the date to the given format with correct timezone
 *
 * @param {String} date
 * @param {String} format
 * @returns
 */
export function formatDate(date, format = "LLL") {
  return dayjs.utc(date)
    .tz(dayjs.tz.guess())
    .format(format)
}

/**
 * Format the date to the given format with correct timezone or relative date
 *
 * @param {String} date
 * @param {String} format
 * @returns {String}
 */
export function formatRelativeDate(date, format = "LLL") {
  return differenceFromNow(date) <= 7
      ? dayjs.utc(date).tz(dayjs.tz.guess()).fromNow()
      : formatDate(date, format)
}

/**
 * Get the difference between the given date and now
 *
 * @param {String} date
 * @param {String} unit
 * @returns {Number}
 */
export function differenceFromNow(date, unit = "days") {
  return dayjs().diff(dayjs.utc(date), unit)
}
