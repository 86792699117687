export default {
  /**
   * Update the value of selected platform
   *
   * @param {Object} state
   * @param {String} payload
   */
  UPDATE_SELECTED_PLATFORM(state, payload) {
    state.selectedPlatform = payload
  },

  /**
   * Update the query parameter values
   *
   * @param {Object} state
   * @param {Object} { key: String, value: String|Integer }
   */
  UPDATE_QUERY(state, { key, value }) {
    state.query[key] = value
  },

  /**
   * Update the API response
   *
   * @param {Object} state
   * @param {Object} payload
   */
  UPDATE_RESPONSE(state, payload) {
    state.response.data = payload.data
    state.response.total = payload.total
  },

  /**
   * Update the query parameter values for exports
   *
   * @param {Object} state
   * @param {Object} { key: String, value: String|Integer }
   */
  UPDATE_EXPORT_QUERY(state, { key, value }) {
    state.exportQuery[key] = value
  },

  /**
   * Update the API response for exports
   *
   * @param {Object} state
   * @param {Object} payload
   */
  UPDATE_EXPORT_RESPONSE(state, payload) {
    state.exportResponse.data = payload.data
    state.exportResponse.total = payload.total
  },

  /**
   * Store the profile data in state's profiles array
   *
   * @param {Object} state
   * @param {Object} payload
   */
  PUSH_PROFILE(state, { platform, username, accountId, data, reportId = null, isError = false }) {
    state.profiles.push({ platform, username, accountId, data, reportId, isError })
  },

  /**
   * Reset all the cached items
   *
   * @param {Object} state
   */
  RESET_PROFILES(state) {
    state.profiles = []
  }
}
