// Export a new object instance
export default {
  // Whether or not this module is namespaced
  namespaced: true,

  // Define the getters object
  getters: {
    /**
     * Get the app's name, if no company name is available, use the default name
     *
     * @param {Object} state
     * @param {Object} getters
     * @param {Object} rootState
     * @param {Object} rootGetters
     * @returns {String}
     */
    name(state, getters, rootState, rootGetters) {
      return rootGetters["auth/profile"] && rootGetters["auth/profile"].company_name ? rootGetters["auth/profile"].company_name : window.host.name
    }
  }
}