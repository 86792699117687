export default {
  /**
   * Update the query parameter values
   *
   * @param {Object} state
   * @param {Object} { key: String, value: String|Integer }
   */
  UPDATE_QUERY(state, { key, value }) {
    state.query[key] = value
  },

  /**
   * Update the API results in local state
   *
   * @param {Object} state
   * @param {Object} payload
   */
  UPDATE_API_RESPONSE(state, payload) {
    state.apiResponse = payload
  },

  /**
   * Update the API results in local state
   *
   * @param {Object} state
   * @param {Object} payload
   */
  UPDATE_CREDIT_USAGE_RESPONSE(state, payload) {
    state.creditUsageResponse = payload
  },

  /**
   * Push the filter item to the state's filters array
   *
   * @param Object state
   * @param Object payload
   */
  ADD_FILTER_ITEM(state, payload) {
    state.filters.push(payload)
  },

  /**
   * Find the filter item and update it's data property
   *
   * @param Object state
   * @param Object payload
   */
  UPDATE_FILTER_ITEM(state, { id, data }) {
    const index = state.filters.findIndex((search) => search.id === id)

    // if not found (index = -1), end the execution
    if (index === -1) return

    // else update the entry
    state.filters[index].data = data
  },

  /**
   * Find and remove the item from state's filters array
   *
   * @param Object state
   * @param Symbol id
   */
  REMOVE_FILTER_ITEM(state, id) {
    const index = state.filters.findIndex((search) => search.id === id)

    // if not found (index = -1), end the execution
    if (index === -1) return

    // else delete the entry
    state.filters.splice(index, 1)
  },

  /**
   * Delete all applied filters
   *
   * @param Object state
   * @param Symbol id
   */
  RESET_FILTERS(state) {
    // delete all the items in the array
    state.filters.splice(0, state.filters.length)
  }
}
