export default {
  // Query parameters used when making requests
  query: {
    search: "",

    perPage: 10,
    page: 1,

    sortBy: "id",
    sortOrder: "desc"
  },

  // API response data
  response: {
    "influencer-discovery": {
      data: [],
      total: 10,
    },

    "paid-collaborations": {
      data: [],
      total: 10,
    }
  }
}
