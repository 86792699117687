// Import helper objects
import constants from '@/helpers/constants.js'

// Export the route items
export default [
  {
    path: '/influencer-groups',
    name: 'InfluencerGroupsIndex',
    component: () => import(/* webpackChunkName: "influencer-groups-index" */ '@/views/crm/influencerGroups/Index.vue'),
    meta: {
      title: "Influencer Groups",
      appBarTitle: "Influencer Groups",
      template: "dashboard",
      serviceRequired: constants.model.user.allowedServices.influencerGroups
    }
  },

  {
    path: '/influencer-groups/create',
    name: 'InfluencerGroupsCreate',
    component: () => import(/* webpackChunkName: "influencer-groups-create" */ '@/views/crm/influencerGroups/Create.vue'),
    meta: {
      title: "New Influencer Group",
      appBarTitle: "New Influencer Group",
      template: "dashboard",
      serviceRequired: constants.model.user.allowedServices.influencerGroups
    }
  },

  {
    path: '/influencer-groups/view/:uuid',
    name: 'InfluencerGroupsView',
    redirect: { name: "InfluencerGroupsInfluencers" },
    component: () => import(/* webpackChunkName: "influencer-groups-view" */ '@/views/crm/influencerGroups/View.vue'),
    children: [
      {
        path: "influencers",
        name: "InfluencerGroupsInfluencers",
        component: () => import(/* webpackChunkName: "influencer-groups-view-influencers" */ '@/views/crm/influencerGroups/Influencers.vue'),
        meta: {
          title: "Influencer Group",
          appBarTitle: "Influencer Group",
          template: "dashboard",
          serviceRequired: constants.model.user.allowedServices.influencerGroups,
          isGuestAllowed: true,
          switchTemplateToMinimalIfUnauthenticated: true
        },
      },
      {
        path: "edit",
        name: "InfluencerGroupsEdit",
        component: () => import(/* webpackChunkName: "influencer-groups-edit" */ '@/views/crm/influencerGroups/Edit.vue'),
        meta: {
          title: "Edit Influencer Group",
          appBarTitle: "Edit Influencer Group",
          template: "dashboard",
          serviceRequired: constants.model.user.allowedServices.influencerGroups,
          isGuestAllowed: true,
          switchTemplateToMinimalIfUnauthenticated: true
        },
      },
    ]
  },
]
