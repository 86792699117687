const middleware = (user) => {
  return Boolean(user.is_whitelisted)
}

export default [
  {
    path: '/team',
    name: 'Team/Index',
    component: () => import(/* webpackChunkName: "team-index" */ '@/views/team/Index.vue'),
    meta: {
      title: "Team Manager",
      appBarTitle: "Team Manager",
      template: "dashboard",
      middleware
    }
  },

  // FOR #MEMBERS SECTION
  {
    path: '/team/members',
    name: 'Team/Members/Index',
    component: () => import(/* webpackChunkName: "team-members-index" */ '@/views/team/members/Index.vue'),
    meta: {
      title: "Team Manager",
      appBarTitle: "Team Manager",
      template: "dashboard",
      middleware,
      breadcrumbs: [
        {
          text: "Team Manager",
          to: { name: "Team/Index" }
        },
        "View Members"
      ]
    }
  },

  {
    path: '/team/members/create',
    name: 'Team/Members/Create',
    component: () => import(/* webpackChunkName: "team-members-create" */ '@/views/team/members/Create.vue'),
    meta: {
      title: "Team Manager",
      appBarTitle: "Add Team Member",
      template: "dashboard",
      middleware,
      breadcrumbs: [
        {
          text: "Team Manager",
          to: { name: "Team/Index" }
        },
        "Add Member"
      ]
    }
  },

  {
    path: '/team/members/edit/:userId',
    name: 'Team/Members/Edit',
    component: () => import(/* webpackChunkName: "team-members-edit" */ '@/views/team/members/Edit.vue'),
    meta: {
      title: "Team Manager",
      appBarTitle: "Edit Team Manager",
      template: "dashboard",
      middleware,
      breadcrumbs: [
        {
          text: "Team Manager",
          to: { name: "Team/Index" }
        },
        "Edit Member"
      ]
    }
  },

  {
    path: '/team/members/credit-usage',
    name: 'Team/Members/Usage',
    component: () => import(/* webpackChunkName: "team-members-usage" */ '@/views/team/members/Usage.vue'),
    meta: {
      title: "Credit Usage",
      appBarTitle: "Credit Usage",
      template: "dashboard",
      middleware,
      breadcrumbs: [
        {
          text: "Team Manager",
          to: { name: "Team/Index" }
        },
        "Credit Usage"
      ]
    }
  },

  {
    path: '/team/members/credit-usage/:userId',
    name: 'Team/Members/UsageByMonth',
    component: () => import(/* webpackChunkName: "team-members-usage-by-month" */ '@/views/team/members/UsageByMonth.vue'),
    meta: {
      title: "Credit Usage By Month",
      appBarTitle: "Credit Usage By Month",
      template: "dashboard",
      middleware,
      breadcrumbs: [
        {
          text: "Team Manager",
          to: { name: "Team/Index" }
        },
        {
          text: "Credit Usage",
          to: { name: "Team/Members/Usage" }
        },
        "Month Wise"
      ]
    }
  }
]
