export default {
  /**
   * Update the query values in local state
   *
   * @param {Object} context
   * @param {Object} { key: String, value: String|Integer }
   */
  updateQuery({ commit }, { key, value }) {
    commit("UPDATE_QUERY", { key, value })
  },

  /**
   * Update the local users array
   *
   * @param {Object} context
   */
  async fetchItems({ dispatch, commit, getters }) {
    // Create the loader
    const loaderId = Symbol()
    dispatch("loaders/add", loaderId, { root: true })

    try {
      const queryObject = getters.query

      const queryParams = new window.URLSearchParams({
        q: queryObject.search,
        perPage: queryObject.perPage,
        page: queryObject.page
      })

      const response = await axios({
        url: "/api/team?" + queryParams
      })

      // map and modify the data
      response.data.data = response.data.data.map((item) => {
        return {
          ...item,
          last_active_at: item.last_active_at ? dayjs.utc(item.last_active_at).tz(dayjs.tz.guess()).format("LLL") : 'NA',
          last_active_at_relative: item.last_active_at ? dayjs().to(dayjs.utc(item.last_active_at)) : 'NA'
        }
      })

      // update the vuex state
      commit("UPDATE_API_RESPONSE", response.data)
    } catch (error) {
      // log using the helper function
      logger({ type: "Network Error", error })
    } finally {
      // close the loader
      dispatch("loaders/remove", loaderId, { root: true })
    }
  },

  /**
   * Update the local credit usage array
   *
   * @param {Object} context
   */
  async fetchCreditUsage({ dispatch, commit }) {
    // Show a global loader
    const loaderId = Symbol()
    dispatch("loaders/add", loaderId, { root: true })

    // Make network request
    try {
      // Use helper function
      const response = await axios({
        url: "/api/team-credits"
      })

      // Commit the response
      commit("UPDATE_CREDIT_USAGE_RESPONSE", response.data)
    }
    // Catch any error
    catch (error) {
      // Log the error
      logger({ type: "Credit Usage Fetch Error", error })

      // Show a toast
      dispatch("toasts/add", { text: "An error occurred!" }, { root: true })
    }
    // Nonetheless
    finally {
      // Hide the loader
      dispatch("loaders/remove", loaderId, { root: true })
    }
  }
}
