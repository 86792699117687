
import constants from '@/helpers/constants.js'

export default [
  {
    path: '/content-discovery',
    name: 'ContentDiscoveryHistory',
    component: () => import(/* webpackChunkName: "content-discovery-history" */ '@/views/contentDiscovery/History.vue'),
    meta: {
      title: "Content Discovery",
      appBarTitle: "Content Discovery",
      template: "dashboard",
      serviceRequired: constants.model.user.allowedServices.actionContentDiscovery
    }
  },
  {
    path: '/content-discovery/report/:uuid',
    name: 'ContentDiscoveryReport',
    component: () => import(/* webpackChunkName: "content-discovery-report" */ '@/views/contentDiscovery/Report.vue'),
    meta: {
      title: "Content Discovery Report",
      appBarTitle: "Content Discovery",
      template: "dashboard",
      serviceRequired: constants.model.user.allowedServices.actionContentDiscovery
    }
  },
]
