// Export the hosts
const _hosts = () => ({
  // "localhost:8080": {
  "app.klugklug.com": {
    name: "Klug",
    images: {
      favicon: "/hosts/klug/icon.ico",
      logo: "/hosts/klug/logo.svg",
      navigation: "/hosts/klug/navigation.png",
    },

    theme: {
      type: "light",

      color: {
        text: "#000",
        primary: "#f05d56",
        primaryText: "#fff",
        secondaryText: "#fff",
      }
    },

    allowRegistration: true,
  },

  // "localhost:8080": {
  "uplink.id": {
    name: "Uplink",
    images: {
      favicon: "/hosts/uplink/uplink.png",
      logo: "/hosts/uplink/uplink.png",
      navigation: "/hosts/uplink/uplink.png",
    },

    theme: {
      type: "light",

      color: {
        text: "#000",
        primary: "#fe1719",
        primaryText: "#fff",
        secondaryText: "#fff",
      }
    },

    allowRegistration: false,
  },

  // "localhost:8080": {
  "platform.x10.media": {
    name: "X10 Media",
    images: {
      favicon: "/hosts/x10/icon.png",
      logo: "/hosts/x10/logo.png",
      navigation: "/hosts/x10/navigation.png",
    },

    theme: {
      type: "dark",

      color: {
        primary: "#162fd0",
        secondary: "#404040",
        accent: "#000",

        navigation: "#000",

        text: "#fff",
        primaryText: "#fff",
        secondaryText: "#fff",
      }
    },

    allowRegistration: false,
  },

  // "localhost:8080": {
  "clout.platformance.io": {
    name: "Platformance",
    images: {
      favicon: "/hosts/platformance/icon.jpeg",
      logo: "/hosts/platformance/logo.png",
      navigation: "/hosts/platformance/navigation.png",
    },

    theme: {
      type: "light",

      color: {
        primary: "#0F2952",
        secondary: "#0F2952",
        accent: "#ACD0FC",

        navigation: "#0F2952",

        buttonPrimary: "#74DCBA",
        buttonPrimaryText: "#000",

        text: "#000",
        primaryText: "#fff",
        secondaryText: "#fff",
      }
    },

    allowRegistration: false,
  },

  // "localhost:8080": {
  "madisonworld.in": {
    name: "Madison Connect",
    images: {
      favicon: "/hosts/madison/icon.jpg",
      logo: "/hosts/madison/logo.png",
      navigation: "/hosts/madison/navigation.png",
    },

    theme: {
      type: "light",

      color: {
        primary: "#b1262b",

        text: "#000",
        primaryText: "#fff",
        secondaryText: "#fff",
      }
    },

    allowRegistration: false,
  },

  // "localhost:8080": {
  "inmar.betagged.co": {
    name: "BeTagged",
    images: {
      favicon: "/hosts/betagged/favicon.png",
      logo: "/hosts/betagged/blue.svg",
      navigation: "/hosts/betagged/white.svg",
    },

    theme: {
      type: "light",
      font: "Poppins",

      color: {
        primary: "#000000",
        secondary: "#000000",
        accent: "#EEEEEE",

        navigation: "#2CC3E1",

        buttonPrimary: "#000000",
        buttonPrimaryText: "#FFFFFF",

        text: "#000",
        primaryText: "#fff",
        secondaryText: "#fff",
      }
    },

    allowRegistration: false,
    shouldShowPoweredByText: false
  },

  // "localhost:8080": {
  "app.cloutscout.io": {
    name: "CloutScout",
    images: {
      favicon: "/hosts/cloutscout/favicon.png",
      logo: "/hosts/cloutscout/logo-black.png",
      navigation: "/hosts/cloutscout/logo-white.png",
    },

    theme: {
      type: "light",
      font: "Montserrat",

      color: {
        primary: "#000000",
        secondary: "#000000",
        accent: "#DADAE1",

        navigation: "#5F4DFF",

        buttonPrimary: "#1400D1",
        buttonPrimaryText: "#FFFFFF",

        text: "#000",
        primaryText: "#fff",
        secondaryText: "#fff",
      }
    },

    allowRegistration: false,
  },

  // "localhost:8080": {
  "app.jointly.id": {
    name: "Jointly",
    images: {
      favicon: "/hosts/jointly/logo.png",
      logo: "/hosts/jointly/logo.png",
      navigation: "/hosts/jointly/logo.png",
    },

    theme: {
      type: "light",

      color: {
        primary: "#003399",
        secondary: "#000000",
        accent: "#C8C8C8",

        navigation: "#000000",

        buttonPrimary: "#003399",
        buttonPrimaryText: "#FFFFFF",

        text: "#000",
        primaryText: "#fff",
        secondaryText: "#fff",
      }
    },

    allowRegistration: false,
    shouldShowMenuFooter: false,
  },
})

// Get the hosts
const hosts = _hosts()

// Get the current domain
export default hosts[window.location.host] || hosts["app.klugklug.com"]
