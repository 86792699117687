/**
 * Finds out whether a string value is a valid URL
 *
 * @source https://stackoverflow.com/a/43467144/17622495
 *
 * @param {String} string
 * @returns {Boolean}
 */
export default function isURL(string) {
  let url

  try {
    url = new URL(string);
  } catch (_) {
    return false
  }

  return url.protocol === "http:" || url.protocol === "https:"
}
