import { getClientID } from "@/helpers/clientHelper"

export default {
  /**
   * Update the query values in local state
   *
   * @param {Object} context
   * @param {Object} { key: String, value: String|Integer }
   */
  updateQuery({ commit }, { key, value }) {
    commit("UPDATE_QUERY", { key, value })
  },

  /**
   * Fetch unlocked insights from the API
   *
   * @param {Object} context
   */
  async fetchItems({ dispatch, commit, getters }) {
    // Create the loader
    const loaderId = Symbol("socialSentiments/actions/fetchItems")
    dispatch("loaders/add", loaderId, { root: true })

    try {
      // Compute query parameters
      const queryParams = new window.URLSearchParams(Object.entries(getters.query).filter(([key, value]) => value))

      // Make the network request
      const response = await axios({
        url: "/api/social-sentiments/?" + queryParams
      })

      // Update the vuex state
      commit("UPDATE_RESPONSE", response.data)
    } catch (error) {
      // Log using the helper function
      logger({ type: "Network Error", error, comment: "Error while fetching social sentiments" })

      // Show the error message
      dispatch("toasts/add", { text: "Error while fetching reports!" }, { root: true })
    } finally {
      // Close the loader
      dispatch("loaders/remove", loaderId, { root: true })
    }
  },

  /**
   * Make PUT request to update the model
   *
   * @param {Object} context
   * @param {Object} payload
   */
  async updateItem({ dispatch }, { id, title, sharingAccess }) {
    // Create the loader
    const loaderId = Symbol("socialSentiments/actions/updateItem")
    dispatch("loaders/add", loaderId, { root: true })

    try {
      // Make the network request
      await axios({
        method: "PUT",
        url: `/api/social-sentiments/${id}`,
        data: { title, sharing_access: sharingAccess }
      })

      // Refresh the list of social sentiments
      dispatch("fetchItems")

      // Show the success message
      dispatch("toasts/add", { text: "Updated report!" }, { root: true })
    } catch (error) {
      // Log using the helper function
      logger({ type: "Network Error", error, comment: "Error while updating social sentiment report" })

      // Show the error message
      dispatch("toasts/add", { text: "Error while updating report!" }, { root: true })
    } finally {
      // Close the loader
      dispatch("loaders/remove", loaderId, { root: true })
    }
  },

  /**
   * Make DELETE request to delete the model
   *
   * @param {Object} context
   * @param {Object} payload
   */
  async deleteItem({ dispatch }, { id, showToast = true, fetchItems = true }) {
    // Create the loader
    const loader = Symbol("socialSentiments/actions/deleteItem")
    dispatch("loaders/add", loader, { root: true })

    try {
      // Make the network request
      await axios({
        method: "DELETE",
        url: `/api/social-sentiments/${id}`
      })

      if (fetchItems) {
        // Refresh the list of social sentiments
        dispatch("fetchItems")
      }

      if (showToast) {
        // Show the success message
        dispatch("toasts/add", { text: "Deleted social sentiment report!" }, { root: true })
      }
    } catch (error) {
      // log using the helper function
      logger({ type: "Network Error", error, comment: "Error while deleting social sentiment report" })

      // Show the error message
      dispatch("toasts/add", { text: "Error while deleting report!" }, { root: true })
    } finally {
      // close the loader
      dispatch("loaders/remove", loader, { root: true })
    }
  },

  /**
   * Update the local item in the state
   *
   * @param {Object} context
   * @param {Object} payload
   */
  updateLocalItem({ commit }, payload) {
    commit("UPDATE_ITEM", payload)
  },

  /**
   * Queue the request to generate the PDF report
   *
   * @param {Object} context
   * @param {Object} payload
   */
  async downloadItem({ dispatch }, { modelId, triggerId = null }) {
    // Create the loader
    const loaderId = Symbol()
    dispatch("loaders/add", loaderId, { root: true })

    try {
      await axios({
        url: `/api/social-sentiments/${modelId}/download`,
        method: "POST",
        data: {
          triggerId,
          clientId: getClientID()
        }
      })

      dispatch("toasts/add", { text: "Your download should start in a few moments." }, { root: true })
    } catch (error) {
      // log using the helper function
      logger({ type: "Network Error", error })

      dispatch("toasts/add", { text: "An error occurred while generating the PDF." }, { root: true })
    } finally {
      // close the loader
      dispatch("loaders/remove", loaderId, { root: true })
    }
  }
}
