// Export the mutations object
export default {
  /**
   * Update the value of local state about the Items to show in table
   *
   * @param {Object} state
   * @param {Array} payload
   * @returns {void}
   */
  UPDATE_ITEMS(state, payload) {
    state.items = payload
  },

  /**
   * Update the value of the detailed object in the local store
   *
   * @param {Object} state
   * @param {Object} payload
   * @returns {void}
   */
  UPDATE_DETAILS(state, payload) {
    // Find if an object for it already exists
    const index = state.details.findIndex((search) => search.model.uuid === payload.model.uuid)

    // If it exists
    if (index !== -1) {
      // Remove it
      state.details.splice(index, 1)
    }

    // Push the object into the store
    state.details.push(payload)
  },

  /**
   * Replace the model value in local store
   *
   * @param {Object} state
   * @param {Object} payload
   * @returns {void}
   */
  UPDATE_CAMPAIGN(state, payload) {
    // Find if the object exists
    const detail = state.details.find((search) => search.model.id === payload.id)

    // If it exists
    if (detail) {
      // Update the model value for it
      detail.model = payload
    }
  },

  /**
   * Update the influencer local values
   *
   * @param {Object} state
   * @param {Object} payload
   * @returns {void}
   */
  UPDATE_INFLUENCER(state, { id, data, campaignId }) {
    // Find the campaign object
    const campaign = state.details.find((search) => search.model.id === campaignId)

    // If campaign object not found
    if (!campaign) {
      // End further execution
      return
    }

    // Otherwise, fetch the influencer object
    const influencer = campaign.influencers.find((search) => search.id === id)

    // If influencer object not found
    if (!influencer) {
      // End further execution
      return
    }

    // Otherwise, update the values
    influencer.first_name = data.firstName
    influencer.last_name = data.lastName
    influencer.phone = data.phone
    influencer.email = data.email
    influencer.status = data.status
  },

  /**
   * Push a new note object in the store
   *
   * @param {Object} state
   * @param {Object} payload
   * @returns {void}
   */
  CREATE_NOTE(state, payload) {
    // Find the campaign object
    const campaign = state.details.find((search) => search.model.id === payload.campaign_report_id)

    // If campaign object not found
    if (!campaign) {
      // End further execution
      return
    }

    // Push the note to the end of the list
    campaign.notes.push(payload)
  },

  /**
   * Update values of existing object in the store
   *
   * @param {Object} state
   * @param {Object} payload
   * @returns {void}
   */
  UPDATE_NOTE(state, payload) {
    // Find the campaign object
    const campaign = state.details.find((search) => search.model.id === payload.campaign_report_id)

    // If campaign object not found
    if (!campaign) {
      // End further execution
      return
    }

    // Otherwise, fetch the note object
    const note = campaign.notes.find((search) => search.id === payload.id)

    // If the note object not found
    if (!note) {
      // End further execution
      return
    }

    // Otherwise, update the values
    note.title = payload.title
    note.content = payload.content
    note.updated_at = payload.updated_at
  },

  /**
   * Remove the existing model from the store
   *
   * @param {Object} state
   * @param {Object} payload
   * @returns {void}
   */
  DELETE_NOTE(state, payload) {
    // Find the campaign object
    const campaign = state.details.find((search) => search.model.id === payload.campaign_report_id)

    // If campaign object not found
    if (!campaign) {
      // End further execution
      return
    }

    // Otherwise, fetch the note object's index
    const index = campaign.notes.findIndex((search) => search.id === payload.id)

    // If the note object not found
    if (index === -1) {
      // End further execution
      return
    }

    // Otherwise, remove the object
    campaign.notes.splice(index, 1)
  },

  /**
   * Update the influencer's prices attribute
   *
   * @param {Object} state
   * @param {Object} payload
   * @returns {void}
   */
  UPDATE_PRICES(state, payload) {
    // Find the campaign object
    const campaign = state.details.find((search) => search.model.id === payload.influencer.campaign_report_id)

    // If campaign object not found
    if (!campaign) {
      // End further execution
      return
    }

    // Otherwise, fetch the influencer object
    const influencer = campaign.influencers.find((search) => search.id === payload.influencer.id)

    // If influencer object not found
    if (!influencer) {
      // End further execution
      return
    }

    // Otherwise, update the values
    Object.assign(influencer.prices, payload.prices)
  },

  /**
   * Push a new task object in the store
   *
   * @param {Object} state
   * @param {Object} payload
   * @returns {void}
   */
  CREATE_TASK(state, payload) {
    // Find the campaign object
    const campaign = state.details.find((search) => search.model.id === payload.campaign_report_id)

    // If campaign object not found
    if (!campaign) {
      // End further execution
      return
    }

    // Push the task to the end of the list
    campaign.tasks.push(payload)
  },

  /**
   * Update values of existing object in the store
   *
   * @param {Object} state
   * @param {Object} payload
   * @returns {void}
   */
  UPDATE_TASK(state, payload) {
    // Find the campaign object
    const campaign = state.details.find((search) => search.model.id === payload.campaign_report_id)

    // If campaign object not found
    if (!campaign) {
      // End further execution
      return
    }

    // Otherwise, fetch the task object
    const task = campaign.tasks.find((search) => search.id === payload.id)

    // If the task object not found
    if (!task) {
      // End further execution
      return
    }

    // Otherwise, update the values
    task.type = payload.type
    task.status = payload.status
    task.post_url = payload.post_url
    task.updated_at = payload.updated_at
    task.scheduled_at = payload.scheduled_at
  },

  /**
   * Remove the existing model from the store
   *
   * @param {Object} state
   * @param {Object} payload
   * @returns {void}
   */
  DELETE_TASK(state, payload) {
    // Find the campaign object
    const campaign = state.details.find((search) => search.model.id === payload.campaign_report_id)

    // If campaign object not found
    if (!campaign) {
      // End further execution
      return
    }

    // Otherwise, fetch the task object's index
    const index = campaign.tasks.findIndex((search) => search.id === payload.id)

    // If the task object not found
    if (index === -1) {
      // End further execution
      return
    }

    // Otherwise, remove the object
    campaign.tasks.splice(index, 1)
  },

  /**
   * Push a new email object in the store
   *
   * @param {Object} state
   * @param {Object} payload
   * @returns {void}
   */
  CREATE_EMAIL(state, payload) {
    // Find the campaign object
    const campaign = state.details.find((search) => search.model.id === payload.campaign_report_id)

    // If campaign object not found
    if (!campaign) {
      // End further execution
      return
    }

    // Push the email to the end of the list
    campaign.emails.push(payload)
  },

  /**
   * Update values of existing objects in the store
   *
   * @param {Object} state
   * @param {Object} payload
   * @returns {void}
   */
  UPDATE_EMAILS(state, payload) {
    // Find the campaign object
    const campaign = state.details.find((search) => search.model.id === payload.campaign_report_id)

    // If campaign object not found
    if (!campaign) {
      // End further execution
      return
    }

    // Go through each of the payload object
    for (const email of payload) {
      // Check if it already exists
      const index = campaign.emails.find((search) => search.id === payload.id)

      // If the task object not found
      if (index === -1) {
        // Push it to the stack
        campaign.emails.push(email)
      }
      // Otherwise
      else {
        // Replace it
        campaign.emails.splice(index, 1, email)
      }
    }
  },
}
