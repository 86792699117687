export default {
  /**
   * Update the query values in local state
   *
   * @param {Object} context
   * @param {Object} { key: String, value: String|Integer }
   */
  updateQuery({ commit }, { key, value }) {
    commit("UPDATE_QUERY", { key, value })
  },

  /**
   * Update the local contacts array
   *
   * @param {Object} context
   */
  async fetchItems({ dispatch, commit, getters }) {
    // Create the loader
    const loaderId = Symbol()
    dispatch("loaders/add", loaderId, { root: true })

    try {
      const queryObject = getters.query

      const queryParams = new window.URLSearchParams({
        q: queryObject.search,
        perPage: queryObject.perPage,
        page: queryObject.page
      })

      const response = await axios({
        url: "/api/admin/contacts?" + queryParams
      })

      // map and modify the data
      response.data.data = response.data.data.map((item) => {
        return {
          ...item,
          created_at: item.created_at ? dayjs.utc(item.created_at).tz(dayjs.tz.guess()).format("LLL") : 'NA',
          created_at_relative: item.created_at ? dayjs().to(dayjs.utc(item.created_at)) : 'NA'
        }
      })

      // update the vuex state
      commit("UPDATE_RESPONSE", response.data)
    } catch (error) {
      // log using the helper function
      logger({ type: "Network Error", error })
    } finally {
      // close the loader
      dispatch("loaders/remove", loaderId, { root: true })
    }
  }
}
