
import constants from '@/helpers/constants.js'

export default [
  {
    path: '/competitor-check',
    name: 'CompetitorCheckHistory',
    component: () => import(/* webpackChunkName: "competitor-check-history" */ '@/views/competitorCheck/History.vue'),
    meta: {
      title: "Competitor Check",
      appBarTitle: "Competitor Check",
      template: "dashboard",
      serviceRequired: constants.model.user.allowedServices.competitorCheck
    }
  },
  {
    path: '/competitor-check/report/:uuid',
    name: 'CompetitorCheckReport',
    component: () => import(/* webpackChunkName: "competitor-check-report" */ '@/views/competitorCheck/Report.vue'),
    meta: {
      title: "Competitor Check Report",
      appBarTitle: "Competitor Check",
      template: "dashboard",
      serviceRequired: constants.model.user.allowedServices.competitorCheck
    }
  },
]
