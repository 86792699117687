import users from './users/index.js'
import contacts from './contacts/index.js'

export default {
  namespaced: true,

  // load sub-modules for admin section
  modules: {
    users,
    contacts
  }
}
