export default {
  /**
   * Update the config values for responsiveness
   *
   * @param {Object} state
   * @param {Object} payload
   */
  UPDATE_RESPONSIVENESS(state, payload) {
    state.responsiveness = {
      ...state.responsiveness,
      ...payload
    }
  },

  /**
   * Update the configuration value for module
   *
   * @param {Object} state
   * @param {Object} payload
   */
  UPDATE_VALUE(state, { key, value }) {
    // Check if it exists
    const index = state.values.findIndex((item) => item.key === key)

    // If it exists, update it
    if (index !== -1) {
      state.values[index].value = value
    }
    // If it doesn't exist, add it
    else {
      state.values.push({ key, value })
    }
  }
}
