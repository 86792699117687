// Import node package
import axios from "axios"

// Import helper function
import { localStorageManager, API_DATA } from "./localStorageManager.js"

// Initiate the axios instance
const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 30_000,
  headers: {
    "Accept": "application/json",
    "Content-Type": "application/json"
  }
})

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    const LS = localStorageManager({ name: API_DATA })

    // If we have the access token for the user
    if (LS.access_token) {
      config.headers.Authorization = `Bearer ${LS.access_token}`
    }

    // If we have the admin's token
    if (LS.fallback_token && LS.is_admin) {
      config.headers.Administrator = LS.fallback_token
    }

    // If the URL has per_page set to -1
    if (config.url.includes("per_page=-1")) {
      // Replace the -1 with the 500
      config.url = config.url.replace("per_page=-1", "per_page=500")
    }

    // Return the config
    return config
  },
  (error) => {
    logger({ type: "Axios Request Error", comment: "Through Interceptor", error })

    return Promise.reject(error)
  }
)

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response
  },
  (error) => {
    logger({ type: "Axios Response Error", comment: "Through Interceptor", error })

    // Emit an event to logout the user if code is 401
    if (error.response && error.response.status === 401) {
      // If we don't have the redirect set already
      if (!window.sessionStorage.getItem("redirectAfterLogin")) {
        // Set the path where we should come back after logging in
        window.sessionStorage.setItem("redirectAfterLogin", window.location.pathname + window.location.search)
      }

      // Dispatch the global event
      window.dispatchEvent(new CustomEvent("triggerLogout", {
        detail: {
          module: "axios",
          origin: "axios/interceptors/response",
          message: "Your session has expired, please login again."
        }
      }))
    }

    return Promise.reject(error)
  }
)

// Export the axios instance
export default instance
