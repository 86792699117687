// Import helper functions
import { generateRequestBody } from "./functions.js"

export default {
  /**
   * Return the selected platform's name
   *
   * @param {Object} state
   * @returns {String}
   */
  selectedPlatform(state) {
    return state.selectedPlatform
  },

  /**
   * Return the sortBy filter for data table
   *
   * @param {Object} state
   * @returns {String}
   */
  sortBy(state) {
    return state.sortBy
  },

  /**
   * Return all filter objects that's been applied
   *
   * @param {Object} state
   * @returns {Array}
   */
  filters(state) {
    return state.filters
  },

  /**
   * Return the sort filter
   *
   * @param {Object} state
   * @returns {Object}
   */
  sort(state) {
    return state.sort
  },

  /**
   * Return the audience source
   *
   * @param {Object} state
   * @returns {String}
   */
  audienceSource(state) {
    return state.audienceSource
  },

  /**
   * Search for filters based on their type, like "usernames" or "hashtags"
   *
   * @param {Object} state
   * @returns {Array}
   */
  findFiltersByType: (state) => (type) => {
    return state.filters.filter((search) => search.type === type)
  },

  /**
   * Find the first object in applied filters list
   *
   * @param {Object} state
   * @returns {Object}
   */
  findFilterByType: (state) => (type) => {
    return state.filters.find((search) => search.type === type)
  },

  /**
   * Find the unique filter object by Symbol as identifier
   *
   * @param {Object} state
   * @returns {Object}
   */
  findFilterById: (state) => (id) => {
    return state.filters.find((search) => search.id === id)
  },

  /**
   * Find all filters by type and kind
   *
   * @param {Object} state
   * @returns {Array}
   */
  findFiltersByTypeAndKind: (state) => (type, kind) => {
    return state.filters.filter((search) => search.type === type && search.data.kind === kind)
  },

  /**
   * Find the unique filter object by type and kind
   *
   * @param {Object} state
   * @returns {Object}
   */
  findFilterByTypeAndKind: (state) => (type, kind) => {
    return state.filters.find((search) => search.type === type && search.data.kind === kind)
  },

  /**
   * Return the API response
   *
   * @param {Object} state
   * @returns {Array}
   */
  results: (state) => (platform) => {
    return state.results[platform]
  },

  /**
   * Get the request body for the API
   *
   * @param {Object} state
   * @param {Object} getters
   * @returns {Object}
   */
  requestBody(state, getters) {
    return generateRequestBody(getters)
  }
}
