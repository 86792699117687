// import websocket related packages
import Echo from "laravel-echo"
window.Pusher = require("pusher-js")

// import the axios function
import axios from "./axios.js"

// setup laravel echo in window as a property
const echo = new Echo({
  broadcaster: "pusher",
  key: process.env.VUE_APP_PUSHER_APP_KEY,
  cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
  forceTLS: true,

  // customize the endpoint for authentication
  authEndpoint: `${process.env.VUE_APP_API_BASE_URL}/api/broadcasting/auth`,

  // customize authorizer to support laravel sanctum
  authorizer: (channel) => {
    return {
      authorize: (socketId, callback) => {
        axios
          .post("/api/broadcasting/auth", {
            socket_id: socketId,
            channel_name: channel.name
          })
          .then((response) => {
            callback(false, response.data)
          })
          .catch((error) => {
            callback(true, error)
          })
      }
    }
  }
})

// Listen for the 'state_change' event
echo.connector.pusher.connection.bind("state_change", (states) => {
  if (states.current === "connected") {
    window.HAS_ECHO_CONNECTION_BEEN_SET = "TRUE"

    // Dispatch a custom event as well
    window.dispatchEvent(new Event("echo-connected"))
  }
})

export default echo
