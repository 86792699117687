export default {
  /**
   * Return all of the toast message items from local state
   *
   * @param {Object} state
   * @returns {Array}
   */
  get(state) {
    return state.items
  }
}
