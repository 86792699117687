import { localStorageManager, CLIENT_DATA } from "./localStorageManager";

export function getClientIDs() {
  const store = localStorageManager({ name: CLIENT_DATA })

  return store.IDs ?? []
}

export function setClientID() {
  const id = String(Date.now())
  window.APP_CLIENT_ID = id

  // Sort all of them
  const allIDs = getClientIDs().sort()

  // Add the current one to the end of it
  allIDs.push(id)

  // Save this in storage
  localStorageManager({ name: CLIENT_DATA, key: "IDs", value: allIDs })
}

export function getClientID() {
  return window.APP_CLIENT_ID
}

export function deleteClientID() {
  // Get all of the IDs
  const allIDs = getClientIDs()

  // Find the index
  const index = allIDs.findIndex((search) => search === getClientID())

  // If found
  if (index !== -1) {
    // Delete it
    allIDs.splice(index, 1)
  }

  // Save this in storage
  localStorageManager({ name: CLIENT_DATA, key: "IDs", value: allIDs })
}
