// Import helper objects
import constants from '@/helpers/constants.js'

// Export the route items
export default [
  {
    path: '/paid-collaborations',
    name: 'PaidCollaborationsIndex',
    component: () => import(/* webpackChunkName: "paid-collaborations-index" */ '@/views/paidCollaborations/Index.vue'),
    meta: {
      title: "Paid Collaborations",
      appBarTitle: "Paid Collaborations",
      template: "dashboard",
      serviceRequired: constants.model.user.allowedServices.paidCollaborations
    }
  },

  {
    path: '/paid-collaborations/create',
    name: 'PaidCollaborationsCreate',
    component: () => import(/* webpackChunkName: "paid-collaborations-create" */ '@/views/paidCollaborations/Create.vue'),
    meta: {
      title: "Paid Collaborations Report",
      appBarTitle: "Paid Collaborations Report",
      template: "dashboard",
      serviceRequired: constants.model.user.allowedServices.paidCollaborations
    }
  },

  {
    path: '/paid-collaborations/view/:uuid',
    name: 'PaidCollaborationsView',
    redirect: { name: "PaidCollaborationsReport" },
    component: () => import(/* webpackChunkName: "paid-collaborations-view" */ '@/views/paidCollaborations/View.vue'),
    children: [
      {
        path: "report",
        name: "PaidCollaborationsReport",
        component: () => import(/* webpackChunkName: "paid-collaborations-report" */ '@/views/paidCollaborations/Report.vue'),
        meta: {
          title: "Paid Collaborations Report",
          appBarTitle: "Paid Collaborations Report",
          template: "dashboard",
          serviceRequired: constants.model.user.allowedServices.paidCollaborations,
          isGuestAllowed: true,
          switchTemplateToMinimalIfUnauthenticated: true
        },
      },
    ]
  },
]
