// Provide the relative path for the icons
const generator = () => ({
  tiktok: "/img/socials/tiktok.svg",
  twitter: "/img/socials/twitter.svg",
  youtube: "/img/socials/youtube.svg",
  linkedin: "/img/socials/linkedin.svg",
  snapchat: "/img/socials/snapchat.svg",
  instagram: "/img/socials/instagram.svg",
  web: "/img/socials/web.svg"
})

// Export a new instance
export default generator()
