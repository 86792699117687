/**
 * Tells what the current viewport is
 */
export default {
  computed: {
    innerWidthValue() {
      return this.$store.getters["config/responsiveness"]?.innerWidth || window.innerWidth
    },

    // Reference values form @url https://vuetifyjs.com/en/features/breakpoints/
    isMobileDevice() {
      return this.innerWidthValue < 600
    },

    isTabletDevice() {
      return this.innerWidthValue >= 600 && this.innerWidthValue < 960
    },

    isMediumDevice() {
      return this.innerWidthValue >= 960 && this.innerWidthValue < 1264
    },

    isLargeDevice() {
      return this.innerWidthValue >= 1264 && this.innerWidthValue < 1904
    },

    isExtraLargeDevice() {
      return this.innerWidthValue >= 1904
    },

    isDesktopDevice() {
      return this.innerWidthValue >= 960
    },
  },
}
