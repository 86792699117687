/**
 * If .env asks for it, use trace
 * Otherwise use plain old log
 *
 * @param {Object} context - { type, error, comment }
 */
export default function ({ type, error, comment = null }) {
  if (process.env.VUE_APP_LOGGER_USE_TRACE) console.trace({ type, error, comment })
  else console.log({ type, error, comment })
}
