export default {
  /**
   * Fetch items and store them locally to show in the table
   *
   * @param {Object} context
   */
  async fetchItems({ commit, dispatch }) {
    // Create the loader
    const loaderId = Symbol()
    dispatch("loaders/add", loaderId, { root: true })

    try {
      const response = await axios({
        url: "/api/campaign-plans"
      })

      // otherwise push the results
      commit("UPDATE_ITEMS", response.data)
    } catch (error) {
      // log using the helper function
      logger({ type: "Network Error", error })
    } finally {
      // close the loader
      dispatch("loaders/remove", loaderId, { root: true })
    }
  },

  /**
   * Push an item to the filters array
   *
   * @param Object context
   * @param Object data
   */
   addFilter({ commit }, { type, data }) {
    commit("ADD_FILTER_ITEM", {
      type,
      data,
      id: Symbol(),
      timestamp: Date.now()
    })
  },

  /**
   * Find the item by the unique Symbol identifier and then delete it by splice method
   *
   * @param Object context
   * @param Object data
   */
  removeFilter({ commit }, id) {
    commit("REMOVE_FILTER_ITEM", id)
  },

  /**
   * Find the filter item, delete it and then push a new item
   *
   * @param Object context
   * @param Object data
   */
   async replaceFilter({ getters, dispatch }, { type, data }) {
    // Find the item and remove it
    const itemToDelete = getters.findFilterByType(type)
    if (itemToDelete) {
      await dispatch("removeFilter", itemToDelete.id)
    }

    // Add a new item for that type instead
    await dispatch("addFilter", { type, data })
  },

  /**
   * Find the filter item, update it's values
   *
   * @param Object context
   * @param Object data
   */
  updateFilter({ commit }, { id, data }) {
    commit("UPDATE_FILTER_ITEM", { id, data })
  },

  /**
   * Whenever platform is switched, reset the filters array
   *
   * @param Object context
   */
  resetFilters({ commit }) {
    // Find the item and remove it
    commit("RESET_FILTERS")
  },
}
