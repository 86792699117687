export default {
  /**
   * Pushes a new progress item to the array
   * @param {Object} state
   * @param {Object} payload
   */
  ADD_ITEM(state, payload) {
    // Check if the item already exists
    const index = state.items.findIndex((search) => search.id === payload.id)

    if (index !== -1) {
      state.items.splice(index, 1, payload)
    }
    else {
      state.items.push(payload)
    }
  },

  /**
   * Removes an identifiable progress element from the array
   * @param {Object} state
   * @param {Symbol} payload
   */
  REMOVE_ITEM(state, payload) {
    // Check if the item already exists
    const index = state.items.findIndex((search) => search.id === payload.id)

    if (index !== -1) {
      state.items.splice(index, 1)
    }
  }
}