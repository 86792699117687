export default {
  /**
   * Sets the user info variable for logged in user
   *
   * @param {Object} state
   * @param {Object | Null} payload
   * @returns {void}
   */
  UPDATE_PROFILE(state, payload) {
    state.data = payload
  },

  /**
   *
   * @param {Object} state
   * @param {Object} payload
   * @returns {void}
   */
  UPDATE_META(state, payload) {
    // If state.data.metas is available
    if (state.data.metas) {
      // Find the index
      const index = state.data.metas.findIndex(meta => meta.key === payload.key)

      // If found
      if (index !== -1) {
        // Update the value
        state.data.metas[index].value = payload.value
      }
      // Otherwise
      else {
        // Push a new object
        state.data.metas.push(payload)
      }
    }
  }
}
