/**
 * Title case the first letter of the string
 *
 * @param {String} string
 * @param {Boolean} capitalizeAllWords
 * @returns {String}
 */
export default function titleCaseString(string, capitalizeAllWords = false) {
  return string
    .toLowerCase()
    .split('-')
    .map((word, index) => capitalizeAllWords || index === 0
        ? word.charAt(0).toUpperCase() + word.slice(1)
        : word)
    .join(' ')
}
