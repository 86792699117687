// Import helper functions
import { localStorageManager, API_DATA } from "@/helpers/localStorageManager.js"

// Define the actions object
export default {
  /**
   * Fetches the user details and stores it in state
   *
   * @param {Object} context
   */
  async fetchProfile({ dispatch, commit }) {
    const loaderId = Symbol()
    await dispatch("loaders/add", loaderId, { root: true })

    try {
      const response = await axios({
        url: "/api/profile"
      })

      commit("UPDATE_PROFILE", response.data)

      // Tell everyone that we have the profile data ready
      window.setTimeout(() => {
        window.dispatchEvent(new CustomEvent("fetchedProfile", {
          detail: {
            origin: "vuex/auth/actions/fetchProfile",
            data: response.data
          }
        }))
      }, 1500) // Intentionally wait because it's too quick in production
    } catch (error) {
      logger({ type: "Authentication Error", error })
    } finally {
      await dispatch("loaders/remove", loaderId, { root: true })
    }
  },

  /**
   * Delete the saved API token in localStorage
   *
   * @param {Object} context
   * @returns {void}
   */
  async logout({ commit }) {
    // Send a global event for anyone to listen
    window.dispatchEvent(new CustomEvent("loggingOut", {
      detail: {
        module: "auth",
        origin: "auth/logout",
      }
    }))

    // Unset the vuex store data
    commit("SET_USER_DETAILS", null)

    // Try making a network request
    try {
      // Use helper function
      await axios({
        url: "/api/logout",
        method: "POST"
      })
    }
    // Catch an error
    catch (error) {
      logger({ type: "Logout API error", error })
    }
    // Nonetheless
    finally {
      // Read the localStorage object
      const data = localStorageManager({ name: API_DATA })

      // Delete everything from localStorage
      window.localStorage.clear()

      // If there's some
      if (data.fallback_token) {
        // Save the logged in user's token for new session
        localStorageManager({
          name: API_DATA,
          key: "access_token",
          value: data.fallback_token
        })

        // Redirect to the admin/users page
        window.location.href = data.redirect_after_logout || "/dashboard"
      }
      else {
        // Fire a global event for anyone to listen
        window.dispatchEvent(new CustomEvent('loggedOut', {
          detail: {
            module: "auth",
            origin: "auth/logout",
          }
        }))
      }
    }
  },

  /**
   * Update the user's profile meta value
   *
   * @param {Object} context
   * @param {Object} payload
   */
  async updateMeta({ dispatch, commit }, { key, value }) {
    try {
      // Make network request
      await axios({
        url: "/api/profile/metas",
        method: "PUT",
        data: { key, value }
      })

      // Update the vuex store
      commit("UPDATE_META", { key, value })
    }
    // Catch the error
    catch (error) {
      // Log the error
      logger({ type: "Update Meta Error", error })

      // Show a toast
      dispatch("toasts/add", { text: "An error occurred" }, { root: true })
    }
  }
}
