export default [
  {
    path: '/articles',
    name: 'Articles/Index',
    component: () => import(/* webpackChunkName: "articles-index" */ '@/views/articles/Index.vue'),
    meta: {
      title: "All Articles",
      template: "empty",
      isGuestAllowed: true
    }
  },
  {
    path: '/articles/:slug',
    name: 'Articles/View',
    component: () => import(/* webpackChunkName: "articles-view" */ '@/views/articles/View.vue'),
    meta: {
      title: "View Article",
      template: "empty",
      isGuestAllowed: true
    }
  },
]
