/**
 * Generate a random string of a given length
 *
 * @param {Number} length
 * @returns {String}
 */
export default function generateRandomString(length = 8) {
  // Define the charset
  const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"

  // Initialize the final string
  let finalString = ""

  // Generate the string
  for (var i = 0, n = charset.length; i < length; ++i) {
    // Generate a random character from the charset
    finalString += charset.charAt(Math.floor(Math.random() * n))
  }

  // Return the final string
  return finalString
}
