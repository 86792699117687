import store from "@/store/index.js"

export default async function fetchProfile() {
  // If authentication is not set, and access token is available
  // Make the network request
  // if (!store.getters["auth/isAuthenticated"] && getAccessToken()) {
  if (getAccessToken()) {
    await store.dispatch("auth/fetchProfile")
  }
}
