
import constants from '@/helpers/constants.js'

export default [
  {
    path: '/campaign-planning',
    name: 'CampaignPlanningIndex',
    component: () => import(/* webpackChunkName: "campaign-planning-index" */ '@/views/campaignPlanning/Index.vue'),
    meta: {
      title: "Campaign Planning",
      appBarTitle: "Campaign Planning",
      template: "dashboard",
      serviceRequired: constants.model.user.allowedServices.campaignPlanning
    }
  },
  {
    path: '/campaign-planning/:campaignPlanId',
    name: 'CampaignPlanningDetails',
    component: () => import(/* webpackChunkName: "campaign-planning-details" */ '@/views/campaignPlanning/Details.vue'),
    meta: {
      title: "Campaign Planning",
      appBarTitle: "Campaign Planning",
      template: "dashboard",
      serviceRequired: constants.model.user.allowedServices.campaignPlanning
    }
  },
  {
    path: '/influencers',
    name: 'CampaignPlanningSearch',
    component: () => import(/* webpackChunkName: "campaign-planning-search" */ '@/views/campaignPlanning/Search.vue'),
    meta: {
      title: "Campaign Planning",
      appBarTitle: "Campaign Planning",
      template: "dashboard",
      serviceRequired: constants.model.user.allowedServices.campaignPlanning
    }
  },
  {
    path: '/campaign-planning/:campaignPlanId/search',
    name: 'CampaignPlanningDetailsSearch',
    component: () => import(/* webpackChunkName: "campaign-planning-search" */ '@/views/campaignPlanning/Search.vue'),
    meta: {
      title: "Campaign Planning",
      appBarTitle: "Campaign Planning",
      template: "dashboard",
      serviceRequired: constants.model.user.allowedServices.campaignPlanning
    }
  },
]
