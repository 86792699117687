export default {
  // Query parameters used when making requests
  query: {
    search: "",
    perPage: 10,
    page: 1
  },

  // the entire API response
  // Has things like, current_page, total, data etc.
  response: null
}
