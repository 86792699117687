// Export the getters object
export default {
  /**
   * Return the query
   *
   * @param {Object} state
   * @returns {Object}
   */
  query(state) {
    return state.query
  },

  /**
   * Return the response
   *
   * @param {Object} state
   * @returns {Object}
   */
  response(state) {
    return state.response
  },

  /**
   * Return the detailed items
   *
   * @param {Object} state
   * @returns {Array}
   */
  details(state) {
    return state.details
  },

  /**
   * Get the detailed object by uuid
   *
   * @param {Object} state
   * @param {String} uuid
   * @returns {Object}
   */
  detailByUuid: (state) => (uuid) => {
    return state.details.find((search) => search.model.uuid === uuid)
  },

  /**
   * Return the selected platform
   *
   * @param {Object} state
   * @returns {String}
   */
  selectedPlatformById: (state) => (id) => {
    return state.selectedPlatforms.find((search) => search.id === id)?.value || null
  },
}
