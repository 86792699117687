export default {
  // Make all the .env values accessible for the frontend
  env: process.env,

  // For responsiveness
  responsiveness: {
    innerWidth: window.innerWidth // start with this
  },

  // Store configuration values for modules here
  values: []
}
