/**
 * @description Insert tracking code
 * @return {void}
 */
export function insertScripts() {
  // For Google Tag Manager
  if (!window.gtmScriptAdded) {
    const gtmScript = document.createElement('script')

    window.elDD = gtmScript

    gtmScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-TL86LZ6');`

    document.head.appendChild(gtmScript)
    window.gtmScriptAdded = true
  }

  // For Facebook Pixel
  if (!window.fbPixelScriptAdded) {
    const fbPixelScript = document.createElement('script')
    fbPixelScript.innerHTML = `!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window,document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
 fbq('init', '205104335600364');
fbq('track', 'PageView');`

    document.head.appendChild(fbPixelScript)
    window.fbPixelScriptAdded = true
  }
}

/**
 * @description Remove tracking code
 * @return {void}
 */
export function removeScripts() {
  const scripts = document.head.querySelectorAll('script')

  scripts.forEach(script => {
    if (script.innerHTML.includes('GTM-TL86LZ6')) {
      document.head.removeChild(script);
      window.gtmScriptAdded = false
    }
    if (script.innerHTML.includes('fbq')) {
      document.head.removeChild(script)
      window.fbPixelScriptAdded = false
    }
  })
}