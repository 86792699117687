export default {
  /**
   * Update the query values in local state
   *
   * @param {Object} context
   * @param {Object} { key: String, value: String|Integer }
   */
  updateQuery({ commit }, { key, value }) {
    commit("UPDATE_QUERY", { key, value })
  },

  /**
   * Fetch generated reports from the API
   *
   * @param {Object} context
   */
  async fetchItems({ commit, dispatch, getters }) {
    // Create the loader
    const loaderId = Symbol("reports/actions/fetchItems")
    dispatch("loaders/add", loaderId, { root: true })

    try {
      // Compute query parameters
      const queryParams = new window.URLSearchParams(Object.entries(getters.query).filter(([key, value]) => value))

      // Make the network request
      const response = await axios({
        url: "/api/generated-reports/?" + queryParams
      })

      // Otherwise push the results
      commit("UPDATE_RESPONSE", response.data)
    } catch (error) {
      // log using the helper function
      logger({ type: "Network Error", error })

      // Show the error message
      dispatch("toasts/add", { text: "Error while fetching reports!" }, { root: true })
    } finally {
      // close the loader
      dispatch("loaders/remove", loaderId, { root: true })
    }
  },

  /**
   * Make PUT request to update the model
   *
   * @param {Object} context
   * @param {Object} payload
   */
  async updateItem({ dispatch }, { id, title }) {
    // Create the loader
    const loaderId = Symbol("reports/actions/updateItem")
    dispatch("loaders/add", loaderId, { root: true })

    try {
      // Make the network request
      await axios({
        method: "PUT",
        url: `/api/generated-reports/${id}`,
        data: { title }
      })

      // Refresh the list
      dispatch("fetchItems")

      // Show the success message
      dispatch("toasts/add", { text: "Updated report!" }, { root: true })
    } catch (error) {
      // Log using the helper function
      logger({ type: "Network Error", error, comment: "Error while updating generated report" })

      // Show the error message
      dispatch("toasts/add", { text: "Error while updating report!" }, { root: true })
    } finally {
      // Close the loader
      dispatch("loaders/remove", loaderId, { root: true })
    }
  },

  /**
   * Make DELETE request to delete the model
   *
   * @param {Object} context
   * @param {Object} payload
   */
  async deleteItem({ dispatch }, { id, showToast = true, fetchItems = true }) {
    // Create the loader
    const loader = Symbol("reports/actions/deleteItem")
    dispatch("loaders/add", loader, { root: true })

    try {
      // Make the network request
      await axios({
        method: "DELETE",
        url: `/api/generated-reports/${id}`
      })

      if (fetchItems) {
        // Refresh the list
        dispatch("fetchItems")
      }

      if (showToast) {
        // Show the success message
        dispatch("toasts/add", { text: "Deleted the report!" }, { root: true })
      }
    } catch (error) {
      // log using the helper function
      logger({ type: "Network Error", error, comment: "Error while deleting the report" })

      // Show the error message
      dispatch("toasts/add", { text: "Error while deleting report!" }, { root: true })
    } finally {
      // close the loader
      dispatch("loaders/remove", loader, { root: true })
    }
  },
}
