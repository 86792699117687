export default {
  /**
   * Update the query parameter values
   *
   * @param {Object} state
   * @param {Object} { key: String, value: String|Integer }
   */
  UPDATE_QUERY(state, { key, value }) {
    state.query[key] = value
  },

  /**
   * Update the api results in local state
   *
   * @param {Object} state
   * @param {Object} payload
   */
  UPDATE_RESPONSE(state, payload) {
    state.response.data = payload.data
    state.response.total = payload.total
  },

  /**
   * Update the selected items in local state
   *
   * @param {Object} state
   * @param {Array} payload
   */
  UPDATE_ITEM(state, payload) {
    // Check if we have this model in our local state
    const index = state.response.data.findIndex((search) => search.id === payload.localData.modelId)

    // If we do have this indeed
    if (index !== -1) {
      // Update the status value if available
      if (payload.localData.status) {
        state.response.data[index].status = payload.localData.status
      }

      // Update the items if available
      if (payload.localData.items) {
        state.response.data[index].items = payload.localData.items
      }

      // Update the errors if available
      if (payload.localData.errors) {
        state.response.data[index].errors = payload.localData.errors
      }
    }
  }
}
