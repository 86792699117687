import contentDiscovery from './contentDiscovery'
import influencerDiscovery from './influencerDiscovery'

export default {
  namespaced: true,

  modules: {
    contentDiscovery,
    influencerDiscovery
  }
}
