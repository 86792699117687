/**
 * Add alpha to the RGB value
 *
 * @param {String} rgb
 * @param {Number} alpha
 * @returns {String}
 */
export function rgbToRgba(rgb, alpha) {
  return `rgba(${rgb}, ${alpha})`;
}

/**
 * Convert a hex color string to an rgb string
 *
 * @param {String} hex
 * @returns {String}
 */
export function hexToRgb(hex) {
  // Remove the hash at the start if it's there
  hex = hex.charAt(0) === '#' ? hex.slice(1) : hex;

  // Parse r, g, b values
  let bigint = parseInt(hex, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;

  return `${r}, ${g}, ${b}`;
}

/**
 * Lighten a hex color by a percentage
 *
 * @param {String} hex
 * @param {Number} percent
 * @returns {String}
 */
export function lighten(hex, percent) {
  const bigint = parseInt(hex.replace('#',''), 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255

  const adjust = (color, adjustment) => {
    return Math.round(color + (255 - color) * adjustment / 100)
  }

  return `#${adjust(r, percent).toString(16).padStart(2, '0')}${adjust(g, percent).toString(16).padStart(2, '0')}${adjust(b, percent).toString(16).padStart(2, '0')}`
}

/**
 * Darken a hex color by a percentage
 *
 * @param {String} hex
 * @param {Number} percent
 * @returns {String}
 */
export function darken(hex, percent) {
  const bigint = parseInt(hex.replace('#',''), 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255

  const adjust = (color, adjustment) => {
    return Math.round(color - color * adjustment / 100)
  }

  return `#${adjust(r, percent).toString(16).padStart(2, '0')}${adjust(g, percent).toString(16).padStart(2, '0')}${adjust(b, percent).toString(16).padStart(2, '0')}`
}

// Export the object
export default {
  darken,
  lighten,

  hexToRgb,
  rgbToRgba,
}