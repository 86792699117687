
/**
 * @source https://dev.to/jorik/country-code-to-flag-emoji-a21
 *
 * @param String countryCode
 * @returns String
 */
export default function getFlagEmoji(countryCode) {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map(char =>  127397 + char.charCodeAt())

  return String.fromCodePoint(...codePoints)
}
