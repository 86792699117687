export default {
  /**
   * Update the value of selected platform
   *
   * @param {Object} state
   * @param {String} payload
   */
  UPDATE_SELECTED_PLATFORM(state, payload) {
    state.selectedPlatform = payload
  },

  /**
   * Could either be "followers" or "engagements"
   *
   * @param {Object} state
   * @param {String} payload
   */
  UPDATE_SORT_BY(state, payload) {
    state.sortBy = payload
  },

  /**
   * Update the sort value
   *
   * @param {Object} state
   * @param {Object} payload
   * @returns {void}
   */
  UPDATE_SORT(state, payload) {
    state.sort = payload
  },

  /**
   * Update the audience source
   *
   * @param {Object} state
   * @param {Object} payload
   * @returns {void}
   */
  UPDATE_AUDIENCE_SOURCE(state, payload) {
    state.audienceSource = payload
  },

  /**
   * Push the filter item to the state's filters array
   *
   * @param {Object} state
   * @param {Object} payload
   */
  ADD_FILTER_ITEM(state, payload) {
    state.filters.push(payload)
  },

  /**
   * Find the filter item and update it's data property
   *
   * @param {Object} state
   * @param {Object} payload
   */
  UPDATE_FILTER_ITEM(state, { id, data }) {
    const index = state.filters.findIndex((search) => search.id === id)

    // if not found (index = -1), end the execution
    if (index === -1) return

    // else update the entry
    state.filters[index].data = data
  },

  /**
   * Find and remove the item from state's filters array
   *
   * @param {Object} state
   * @param {Symbol} id
   */
  REMOVE_FILTER_ITEM(state, id) {
    const index = state.filters.findIndex((search) => search.id === id)

    // if not found (index = -1), end the execution
    if (index === -1) return

    // else delete the entry
    state.filters.splice(index, 1)
  },

  /**
   * Delete all applied filters
   *
   * @param {Object} state
   * @param {Symbol} id
   */
  RESET_FILTERS(state) {
    // Delete all the items in the array
    state.filters.splice(0, state.filters.length)

    // Reset the sort and audience source
    state.sort = null
    state.audienceSource = null
  },

  /**
   * Delete the search results for pages
   *
   * @param {Object} state
   * @param {Object} payload
   */
  RESET_RESULTS(state, { platform }) {
    state.results[platform].currentPage = 1
    state.results[platform].data.splice(0, state.results[platform].data.length)
  },

  /**
   * Find and update the search results for API data
   *
   * @param {Object} state
   * @param {Object} payload
   */
  UPDATE_RESULTS(state, { platform, data }) {
    state.results[platform].savedSearch = data.savedSearch
    state.results[platform].currentPage = data.currentPage
    state.results[platform].total = data.total

    // Reset and delete previous accounts if it's the first page
    if (data.currentPage === 1) {
      state.results[platform].accounts = []
    }

    // For each account in response
    for (const account of data.accounts) {
      // Find the index of already existent data
      const index = state.results[platform].accounts.findIndex((search) => search.user_id === account.user_id)

      // If it does exist
      if (index !== -1) {
        // Replace it
        state.results[platform].accounts.splice(index, 1, account)
      }
      // Otherwise
      else {
        // Add a new item to array
        state.results[platform].accounts.push(account)
      }
    }
  }
}
