// Export the actions object
export default {
  /**
   * Update the query values in local state
   *
   * @param {Object} context
   * @param {Object} { key: String, value: String|Integer }
   */
  updateQuery({ commit }, { key, value }) {
    commit("UPDATE_QUERY", { key, value })
  },

  /**
   * Update the selected platform's value
   *
   * @param {Object} context
   * @param {String} payload
   */
  updateSelectedPlatform({ commit }, payload) {
    commit("UPDATE_SELECTED_PLATFORM", payload)
  },

  /**
   * Update the selected influencer's value
   *
   * @param {Object} context
   * @param {String} payload
   */
  updateSelectedInfluencer({ commit }, payload) {
    commit("UPDATE_SELECTED_INFLUENCER", payload)
  },

  /**
   * Fetch items and store them locally to show in the table
   *
   * @param {Object} context
   * @returns {void}
   */
  async fetchItems({ commit, dispatch, getters }) {
    // Create the loader
    const loaderId = Symbol()
    dispatch("loaders/add", loaderId, { root: true })

    try {
      // Compute query parameters
      const queryParams = new window.URLSearchParams(Object.entries(getters.query).filter(([key, value]) => value))

      // Make the network request
      const response = await axios({
        url: "/api/competitor-checks?" + queryParams
      })

      // otherwise push the results
      commit("UPDATE_RESPONSE", response.data)
    } catch (error) {
      // log using the helper function
      logger({ type: "Network Error", error })
    } finally {
      // close the loader
      dispatch("loaders/remove", loaderId, { root: true })
    }
  },

  /**
   * Make PUT request to update the model
   *
   * @param {Object} context
   * @param {Object} payload
   */
  async updateItem({ dispatch }, { id, title, sharingAccess }) {
    // Create the loader
    const loaderId = Symbol("competitorChecks/actions/updateItem")
    dispatch("loaders/add", loaderId, { root: true })

    try {
      // Make the network request
      await axios({
        method: "PUT",
        url: `/api/competitor-checks/${id}`,
        data: { title, sharing_access: sharingAccess }
      })

      // Refresh the list
      dispatch("fetchItems")

      // Show the success message
      dispatch("toasts/add", { text: "Updated report!" }, { root: true })
    } catch (error) {
      // Log using the helper function
      logger({ type: "Network Error", error, comment: "Error while updating the report" })

      // Show the error message
      dispatch("toasts/add", { text: "Error while updating report!" }, { root: true })
    } finally {
      // Close the loader
      dispatch("loaders/remove", loaderId, { root: true })
    }
  },

  /**
   * Make DELETE request to delete the model
   *
   * @param {Object} context
   * @param {Object} payload
   */
  async deleteItem({ dispatch }, { id, showToast = true, fetchItems = true }) {
    // Create the loader
    const loader = Symbol("competitorChecks/actions/deleteItem")
    dispatch("loaders/add", loader, { root: true })

    try {
      // Make the network request
      await axios({
        method: "DELETE",
        url: `/api/competitor-checks/${id}`
      })

      if (fetchItems) {
        // Refresh the list
        dispatch("fetchItems")
      }

      if (showToast) {
        // Show the success message
        dispatch("toasts/add", { text: "Deleted the report!" }, { root: true })
      }
    } catch (error) {
      // log using the helper function
      logger({ type: "Network Error", error, comment: "Error while deleting the report" })

      // Show the error message
      dispatch("toasts/add", { text: "Error while deleting report!" }, { root: true })
    } finally {
      // close the loader
      dispatch("loaders/remove", loader, { root: true })
    }
  },

  /**
   * Update the local item in the state
   *
   * @param {Object} context
   * @param {Object} payload
   */
  updateLocalItem({ commit }, payload) {
    commit("UPDATE_ITEM", payload)
  },
}
