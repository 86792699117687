// Export the mutations object
export default {
  /**
   * Update the query parameter values
   *
   * @param {Object} state
   * @param {Object} { key: String, value: String|Integer }
   */
  UPDATE_QUERY(state, { key, value }) {
    state.query[key] = value
  },

  /**
   * Update the API response
   *
   * @param {Object} state
   * @param {Object} payload
   */
  UPDATE_RESPONSE(state, payload) {
    state.response.data = payload.data
    state.response.total = payload.total
  },

  /**
   * Update the value of selected platform
   *
   * @param {Object} state
   * @param {String} payload
   */
  UPDATE_SELECTED_PLATFORM(state, payload) {
    state.selectedPlatform = payload
  },

  /**
   * Update the value of selected influencer
   *
   * @param {Object} state
   * @param {String} payload
   */
  UPDATE_SELECTED_INFLUENCER(state, payload) {
    state.selectedInfluencer = payload
  },

  /**
   * Update the selected items in local state
   *
   * @param {Object} state
   * @param {Array} payload
   */
  UPDATE_ITEM(state, payload) {
    // Check if we have this model in our local state
    const index = state.response.data.findIndex((search) => search.id === payload.localData.modelId)

    // If we do have this indeed
    if (index !== -1) {
      // If we have a status value
      if (payload.localData.status) {
        state.response.data[index].status = payload.localData.status
      }

      // If we have a posts count value
      if (payload.localData.postsCount) {
        state.response.data[index].posts_count = payload.localData.postsCount
      }

      // If we have the influencer preview
      if (payload.localData.influencerPreview) {
        state.response.data[index].influencer_preview = payload.localData.influencerPreview
      }
    }
  }
}
