export default {
  /**
   * Pushes a loader instance in the loaders array
   *
   * @param {object} state
   * @param {object|function} payload
   */
    PUSH_TO_LOADERS(state, payload) {
    // if the typeof payload is a Symbol
    if (typeof payload === "symbol") {
      state.items.push({
        id: payload
      })
    }

    // otherwise if the type is already an Object, push it
    else if (typeof payload === "object") {
      state.items.push(payload)
    }
  },

  /**
   * Removes a loader instance in the loaders array
   *
   * @param {object} state
   * @param {symbol} payload
   */
  REMOVE_FROM_LOADERS(state, payload) {
    // if the typeof payload is a Symbol
    if (typeof payload === "symbol") {
      const index = state.items.findIndex((search) => search.id === payload)

      if (index !== -1) {
        // call any callback function if there is any
        if (typeof state.items[index].callback === "function") {
          state.items[index].callback()
        }

        // delete the instance from array
        state.items.splice(index, 1)
      }
    }
  }
}