export default {
  /**
   * Commit mutation to push toast item to the state array
   *
   * @param {Object} context
   * @param {Object} payload
   */
  add({ commit }, payload) {
    commit("ADD_ITEM", payload)
  },

  /**
   * Find and remove the toast item from state array
   *
   * @param {Object} context
   * @param {Symbol} payload
   */
  remove({ commit }, payload) {
    commit("REMOVE_ITEM", payload)
  }
}