<template>
  <div>
    <!-- Show the template -->
    <component :is="currentTemplate"></component>

    <!-- Show the contact form popup -->
    <contact-form />

    <!-- Show the logging out popup -->
    <div
      v-if="isLoggingOut"
      class="logout-popup"
      :style="fontStyle"
    >
      <div>
        <div class="logout-popup__title">
          Logging you out...
        </div>

        <hr />

        <div class="logout-popup__text">
          redirecting you to the login page
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Import the template components
const EmptyTemplate = () => import(/* webpackChunkName: "template-empty" */ "@/templates/empty.vue")
const MinimalTemplate = () => import(/* webpackChunkName: "template-minimal" */ "@/templates/minimal.vue")
const DashboardTemplate = () => import(/* webpackChunkName: "template-dashboard" */ "@/templates/dashboard.vue")

// Import the contact form component
const ContactForm = () => import(/* webpackChunkName: "contact-form" */ "@/components/common/ContactForm.vue")

// Import helper functions
import messageEvents from "@/helpers/messageEvents"
import { setClientID, deleteClientID } from "@/helpers/clientHelper"

// Export the SFC
export default {
  // Name of the component
  name: 'App',

  // Register the children components
  components: {
    EmptyTemplate,
    MinimalTemplate,
    DashboardTemplate,

    ContactForm
  },

  // Define the data property
  data: () => ({
    templatesMap: {
      empty: "empty-template",
      minimal: "minimal-template",
      dashboard: "dashboard-template"
    },

    isLoggingOut: false
  }),

  // Define readonly computed properties
  computed: {
    /**
     * Get the current template
     *
     * @returns {string}
     */
    currentTemplate() {
      return this.templatesMap[this.$route.meta.template || "dashboard"]
    }
  },

  /**
   * As soon as the component is about to be created
   *
   * @returns {void}
   */
  beforeCreate() {
    // Create a tab ID
    setClientID()

    // Create a global subscribers store for notifications
    messageEvents.initiate()
  },

  /**
   * As soon as the component has been created
   *
   * @returns {void}
   */
  created() {
    // Also set a window event listener to delete this ID
    window.addEventListener("beforeunload", () => {
      // Delete the client ID
      deleteClientID()
    })

    // Bind the global event for viewport helpers
    window.addEventListener("resize", () => {
      this.$store.dispatch("config/updateResponsivenessInnerWidth")
    })
  },

  /**
   * As soon as the component has been rendered
   *
   * @returns {void}
   */
  mounted() {
    // Handle the loggingOut event
    window.addEventListener("loggingOut", () => {
      this.isLoggingOut = true
    })

    // Handle the loggedOut event
    window.addEventListener("loggedOut", () => {
      // Redirect to make sure no local state for the app is saved
      window.location = "/login"
    })

    // Can be triggered from anywhere, used to redirect user to login and delete the current session
    window.addEventListener("triggerLogout", (e) => {
      // Make sure to only show alert once
      if (window.hasShownLogoutAlert !== true) {
        window.hasShownLogoutAlert = true

        // If there's a message, show an alert
        if (e.detail.message) {
          alert(e.detail.message)
        }

        // Now trigger the vuex action
        this.$store.dispatch("auth/logout")
      }
    })

    // Bind the global event for viewport helpers
    window.addEventListener("fetchAuthUserProfile", () => {
      fetchProfile()
    })
  }
}
</script>

<style lang="stylus">
.logout-popup
  position fixed
  left 0
  top 0
  width 100vw
  height 100vh
  z-index 100000001
  background var(--v-accent)
  display flex
  justify-content center
  align-items center
  text-align center
  padding 0 1.5em

  &__title
    color var(--v-primary)
    font-size 2rem
    font-weight bold

  hr
    margin 1.5em 0

  &__text
    color var(--v-text)
    font-size 1.125rem
    opacity 0.5
</style>
