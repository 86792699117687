export default {
  /**
   * Calls mutation to push the payload in loaders array
   * If no payload is passed, we create a new symbol instance (not recommended)
   *
   * @param {function} commit
   * @param {symbol|object|null} payload
   */
    add({ commit }, payload = null) {
    commit("PUSH_TO_LOADERS", payload || Symbol())
  },

  /**
   * Uses the unique identifier to find and remove element from loaders array
   *
   * @param {function} commit
   * @param {symbol} payload
   */
  remove({ commit }, payload) {
    commit("REMOVE_FROM_LOADERS", payload)
  }
}
