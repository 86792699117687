// Import helper functions
import isEqual from "lodash.isequal"
import { getValue, getValueByKind } from "@/helpers/store.js"

// Define the default object for filters
const defaultFilters = {
  "audience_geo": [],
  "geo": [],
  "audience_lang": {
    "code": "",
    "weight": ""
  },
  "lang": {
    "code": ""
  },
  "audience_brand": [],
  "brand": [],
  "has_ads": false,
  "ads_brands": [],
  "audience_brand_category": [],
  "brand_category": [],
  "audience_gender": {
    "code": "",
    "weight": ""
  },
  "gender": {
    "code": "",
    "weight": ""
  },
  "audience_age": [],
  "age": {
    "left_number": "",
    "right_number": ""
  },
  "audience_relevance": {
    "value": ""
  },
  "relevance": {
    "value": "",
    "weight": ""
  },
  "text": [],
  "followers": {
    "left_number": "",
    "right_number": ""
  },
  "engagements": {
    "left_number": "",
    "right_number": ""
  },
  "engagement_rate": {
    "value": null,
    "operator": "gte"
  },
  "with_contact": [],
  "is_verified": false,
  "last_posted": "",
  "audience_credibility_class": "",
  "views": {
    "left_number": "",
    "right_number": ""
  },
  "audience_race": {
    "code": "",
    "weight": ""
  },
  "keywords": ""
}

export function computeAgeArray(filter) {
  // if there's none applied, return default value
  if (!filter) return []

  // otherwise map the min and max
  const ageMap = [
    {
      from: 13,
      to: 18,
      text: "13-17"
    },
    {
      from: 18,
      to: 25,
      text: "18-24"
    },
    {
      from: 25,
      to: 35,
      text: "25-34"
    },
    {
      from: 35,
      to: 45,
      text: "35-44"
    },
    {
      from: 45,
      to: 65,
      text: "45-64"
    }
  ]

  const minValue = filter.data.inputs.from
  const maxValue = filter.data.inputs.to
  const weight = filter.data.inputs.weight

  const filteredAgeMap = ageMap.filter((item) => item.from >= minValue && item.to <= maxValue)

  return filteredAgeMap.map((item) => ({
    code: item.text,
    weight
  }))
}

export function generateRequestBody(getters) {
  const body = {
    // find the value and if could not, return the default one
    audience_source: getters.audienceSource || getValue({ type: "audienceType", defaultValue: "any" }, getters.findFilterByType),
    filter: {
      // @LOCATION
      audience_geo: getters
        .findFiltersByTypeAndKind("location", "audience")
        .map((item) => item.data.inputs.requestData),

      geo: getters
        .findFiltersByTypeAndKind("location", "influencer")
        .map((item) => item.data.inputs.requestData),

      // @LANGUAGE
      audience_lang: getValueByKind({
        type: "language",
        kind: "audience",
        path: "requestData",
        defaultValue: defaultFilters.audience_lang
      }, getters.findFilterByTypeAndKind),

      lang: getValueByKind({
        type: "language",
        kind: "influencer",
        path: "requestData",
        defaultValue: defaultFilters.lang
      }, getters.findFilterByTypeAndKind),

      // @BRAND
      audience_brand: getters.findFiltersByTypeAndKind("brand", "audience").map((item) => ({ id: item.data.inputs.id, weight: item.data.inputs.weight })),

      brand: getters.findFiltersByTypeAndKind("brand", "influencer").map((item) => item.data.inputs.id),

      // @PARTNERSHIP
      has_ads: getters.findFilterByTypeAndKind("partnership", "any") ? true : false,

      ads_brands: getters.findFiltersByTypeAndKind("partnership", "influencer").map((item) => item.data.inputs.id),

      // @INTEREST
      audience_brand_category: getters.findFiltersByTypeAndKind("interest", "audience").map((item) => ({
        id: item.data.inputs.id,
        weight: item.data.inputs.weight
      })),

      brand_category: getters.findFiltersByTypeAndKind("interest", "influencer").map((item) => item.data.inputs.id),

      // @GENDER
      audience_gender: {
        "code": getValueByKind({ type: "gender", kind: "audience" }, getters.findFilterByTypeAndKind),
        "weight": getValueByKind({ type: "gender", kind: "audience", path: "weight" }, getters.findFilterByTypeAndKind)
      },

      gender: {
        "code": getValueByKind({ type: "gender", kind: "influencer" }, getters.findFilterByTypeAndKind),
        "weight": ""
      },

      // @AGE
      audience_age: computeAgeArray(getters.findFilterByTypeAndKind("age", "audience")),

      age: {
        left_number: getValueByKind({ type: "age", kind: "influencer", path: "from" }, getters.findFilterByTypeAndKind),
        // in case when it's the 65+ option
        // the value for that's 100
        // but we'd need to send an empty string instead of that
        right_number:
          getValueByKind({ type: "age", kind: "influencer", path: "to" }, getters.findFilterByTypeAndKind) === 100
            ? ""
            : getValueByKind({ type: "age", kind: "influencer", path: "to" }, getters.findFilterByTypeAndKind)
      },

      // @LOOKALIKES
      // @TODO - Relevant hashtags with lookAlike usernames and their weight using an input
      audience_relevance: {
        value: getValueByKind({ type: "lookAlike", kind: "audience", path: "lookalikeId" }, getters.findFilterByTypeAndKind)
      },

      relevance: {
        value: [
          ...getters.findFiltersByTypeAndKind("lookAlike", "influencer").map((item) => {
            const one = item.data.inputs.username || item.data.inputs.custom_name || item.data.inputs.user_id
            const two = item.data.inputs.user_id || item.data.inputs.custom_name || item.data.inputs.username

            return "@" + (getters.selectedPlatform === "youtube" ? two : one)
          }),

          ...getters.findFiltersByType("relevance").map((item) => "#" + item.data.inputs.tag)
        ].join(" "),
        weight: getValue({ type: "relevanceWeight" }, getters.findFilterByType)
      },

      // @BIO
      text: getValue({ type: "bio", defaultValue: [] }, getters.findFilterByType),

      // @FOLLOWERS
      followers: {
        left_number: getValue({ type: "followers", path: "from" }, getters.findFilterByType),
        right_number: getValue({ type: "followers", path: "to" }, getters.findFilterByType)
      },

      // @ENGAGEMENTS
      engagements: {
        left_number: getValue({ type: "engagements", path: "from" }, getters.findFilterByType),
        right_number: getValue({ type: "engagements", path: "to" }, getters.findFilterByType)
      },

      engagement_rate: {
        value: getValue({ type: "engagements", path: "rate", defaultValue: null }, getters.findFilterByType),
        operator: "gte"
      },

      // @CONTACTS
      with_contact: getters.findFiltersByType("contacts").map((item) => ({
        type: item.data.inputs.value,
        action: "should"
      })),

      // @VERIFIED
      is_verified: getters.findFilterByType("verified") ? true : false,

      // "total_views_growth": {
      //   "interval": "",
      //   "operator": "gte",
      //   "value": ""
      // },
      "last_posted": getValue({ type: "lastPosted" }, getters.findFilterByType),

      "audience_credibility_class": "",
      // "audience_credibility_class": getValue({ type: "audienceCredibilityClass" }, getters.findFilterByType),

      "views": {
        left_number: getValue({ type: "views", path: "from" }, getters.findFilterByType),
        right_number: getValue({ type: "views", path: "to" }, getters.findFilterByType)
      },
      "audience_race": {
        "code": "",
        "weight": ""
      },
      "keywords": getValue({ type: "keywords" }, getters.findFilterByType)
    },
    "sort": getters.sort || {
      "direction": "desc",
      "field": getters.sortBy
    }
  }

  // If the sortBy is lowest_followers
  if (getters.sortBy === "lowest_followers") {
    body.sort.direction = "asc"
    body.sort.field = "followers"
  }

  // If the sortBy is highest_followers
  if (getters.sortBy === "highest_followers") {
    body.sort.direction = "desc"
    body.sort.field = "followers"
  }

  // add filter followers growth if available
  const filterGrowthFollowers = getters.findFilterByType("growthFollowers")
  if (filterGrowthFollowers) {
    body.filter.followers_growth = {
      interval: filterGrowthFollowers.data.inputs.interval.value,
      operator: filterGrowthFollowers.data.inputs.weight.value.operator,
      value: filterGrowthFollowers.data.inputs.weight.value.percentage
    }
  }

  // add filter views growth if available
  const filterGrowthViews = getters.findFilterByType("growthViews")
  if (filterGrowthViews) {
    body.filter.total_views_growth = {
      interval: filterGrowthViews.data.inputs.interval.value,
      operator: filterGrowthViews.data.inputs.weight.value.operator,
      value: filterGrowthViews.data.inputs.weight.value.percentage
    }
  }

  // add filter views growth if available
  const filterGrowthLikes = getters.findFilterByType("growthLikes")
  if (filterGrowthLikes) {
    body.filter.total_likes_growth = {
      interval: filterGrowthLikes.data.inputs.interval.value,
      operator: filterGrowthLikes.data.inputs.weight.value.operator,
      value: filterGrowthLikes.data.inputs.weight.value.percentage
    }
  }

  // add filter reel plays if available
  const reelViews = getters.findFilterByType("reelViews")
  if (reelViews) {
    body.filter.reels_plays = {
      left_number: getValue({ type: "reelViews", path: "from" }, getters.findFilterByType),
      right_number: getValue({ type: "reelViews", path: "to" }, getters.findFilterByType)
    }
  }

  // add filter saves if available
  const saves = getters.findFilterByType("saves")
  if (saves) {
    body.filter.saves = {
      left_number: getValue({ type: "saves", path: "from" }, getters.findFilterByType),
      right_number: getValue({ type: "saves", path: "to" }, getters.findFilterByType)
    }
  }

  // add filter shares if available
  const shares = getters.findFilterByType("shares")
  if (shares) {
    body.filter.shares = {
      left_number: getValue({ type: "shares", path: "from" }, getters.findFilterByType),
      right_number: getValue({ type: "shares", path: "to" }, getters.findFilterByType)
    }
  }

  // add filter reel plays if available
  const ethnicity = getters.findFilterByType("ethnicity")
  if (ethnicity) {
    body.filter.audience_race = {
      code: ethnicity.data.inputs.value,
      weight: ethnicity.data.inputs.weight
    }
  }

  // If filter for account type exists
  const accountType = getters.findFilterByType("accountType")

  if (accountType) {
    body.filter.account_type = accountType.data.inputs.value
  }

  // If the filter for credibility class is set
  const audienceCredibility = getters.findFilterByType("audience_credibility")

  if (audienceCredibility) {
    body.filter.audience_credibility = audienceCredibility.data.inputs.value

    // If we're setting credibility, we also need to fix the audience_source
    body.audience_source = "followers"
  }

  // If the filter for credibility class is set
  const hasAudienceData = getters.findFilterByType("hasAudienceData")

  if (hasAudienceData) {
    body.filter.has_audience_data = hasAudienceData ? true : false
  }

  const textTags = getters.findFiltersByType("textTags")

  if (textTags.length) {
    body.filter.text_tags = textTags.map((item) => {
      const value = item.data.inputs.value.trim()

      return {
        value: value.substr(1),
        type: value[0] === "#" ? "hashtag" : "mention",
        action: item.data.inputs.action
      }
    })
  }

  const excludePrivateAccounts = getters.findFilterByType("excludePrivateAccounts") ? true : false
  if (excludePrivateAccounts) {
    body.filter.is_hidden = false
  }

  const jobTitle = getters.findFilterByType("jobTitle")
  if (jobTitle) {
    body.filter.job_title = jobTitle.data.inputs.value
  }

  const talksAbout = getters.findFilterByType("talksAbout")
  if (talksAbout) {
    body.filter.talks_about = talksAbout.data.inputs.value
  }

  const workCompany = getters.findFilterByType("workCompany")
  if (workCompany) {
    body.filter.work_company = workCompany.data.inputs.value
  }

  const school = getters.findFilterByType("school")
  if (school) {
    body.filter.school = school.data.inputs.value
  }

  // If gender filters are available
  const likesGender = getValueByKind({ type: "gender", kind: "likers" }, getters.findFilterByTypeAndKind)
  if (likesGender) {
    body.filter.audience_gender = {
      "code": getValueByKind({ type: "gender", kind: "likers" }, getters.findFilterByTypeAndKind),
      "weight": getValueByKind({ type: "gender", kind: "likers", path: "weight" }, getters.findFilterByTypeAndKind)
    }

    body.audience_source = "likers"
  }

  // If gender filters are available
  const followersGender = getValueByKind({ type: "gender", kind: "followers" }, getters.findFilterByTypeAndKind)
  if (followersGender) {
    body.filter.audience_gender = {
      "code": getValueByKind({ type: "gender", kind: "followers" }, getters.findFilterByTypeAndKind),
      "weight": getValueByKind({ type: "gender", kind: "followers", path: "weight" }, getters.findFilterByTypeAndKind)
    }

    body.audience_source = "followers"
  }

  // If audience location filter is available
  const audienceLocations = getters.findFiltersByTypeAndKind("location", "audience")
  if (audienceLocations.length) {
    // Set the audience source to followers
    body.audience_source = "followers"
  }

  // Finally set the keywords action if needed
  const shouldExcludeKeywords = getValue({ type: "keywords", path: "shouldExclude", defaultValue: false }, getters.findFilterByType)
  if (shouldExcludeKeywords) {
    // Check if body.actions is not set
    if (!body.filter.actions) {
      body.filter.actions = []
    }

    // Now set the value
    body.filter.actions.push({
      filter: "keywords",
      action: "not"
    })
  }

  const shouldExcludeJobTitle = getValue({ type: "jobTitle", path: "shouldExclude", defaultValue: false }, getters.findFilterByType)
  if (shouldExcludeJobTitle) {
    // Check if body.actions is not set
    if (!body.filter.actions) {
      body.filter.actions = []
    }

    // Now set the value
    body.filter.actions.push({
      filter: "job_title",
      action: "not"
    })
  }

  const shouldExcludeTalksAbout = getValue({ type: "talksAbout", path: "shouldExclude", defaultValue: false }, getters.findFilterByType)
  if (shouldExcludeTalksAbout) {
    // Check if body.actions is not set
    if (!body.filter.actions) {
      body.filter.actions = []
    }

    // Now set the value
    body.filter.actions.push({
      filter: "talks_about",
      action: "not"
    })
  }

  const shouldExcludeWorkCompany = getValue({ type: "workCompany", path: "shouldExclude", defaultValue: false }, getters.findFilterByType)
  if (shouldExcludeWorkCompany) {
    // Check if body.actions is not set
    if (!body.filter.actions) {
      body.filter.actions = []
    }

    // Now set the value
    body.filter.actions.push({
      filter: "work_company",
      action: "not"
    })
  }

  const shouldExcludeSchool = getValue({ type: "school", path: "shouldExclude", defaultValue: false }, getters.findFilterByType)
  if (shouldExcludeSchool) {
    // Check if body.actions is not set
    if (!body.filter.actions) {
      body.filter.actions = []
    }

    // Now set the value
    body.filter.actions.push({
      filter: "school",
      action: "not"
    })
  }

  // If the platform is LinkedIn
  if (getters.selectedPlatform === "linkedin") {
    // Check if there's some language selected
    if (body.filter.lang.code) {
      // Also send the language name
      body.filter.lang.name = getValueByKind({ type: "language", kind: "influencer", path: "name" }, getters.findFilterByTypeAndKind)
    }
  }

  // Check if there are actions
  const actions = getters.findFiltersByType("actions")

  // If there are actions
  if (actions.length) {
    // Check if the actions object is not set
    if (!body.filter.actions) {
      body.filter.actions = []
    }

    // Loop through each of the actions
    for (const action of actions) {
      console.log("action", action)
      // Make sure this does not already exist
      if (body.filter.actions.find((search) => search.filter === action.data.inputs.filter)) {
        continue
      }

      // Push the action to the actions object
      body.filter.actions.push({
        filter: action.data.inputs.filter,
        action: action.data.inputs.action
      })
    }
  }

  // Get the difference between default and applied filters
  const differenceValues = {}

  // Loop through each of the applied filters
  for (const key of Object.keys(body.filter)) {
    // Check if this is not equal to default value
    if (!isEqual(body.filter[key], defaultFilters[key])) {
      // If not, then push it to the object
      differenceValues[key] = body.filter[key]
    }
  }

  // Rewrite the request body
  body.filter = differenceValues

  // Respond with the computed query object
  return body
}
