// Import helper objects
import constants from '@/helpers/constants.js'

// Export the route items
export default [
  {
    path: '/mention-tracking',
    name: 'MentionTrackingIndex',
    component: () => import(/* webpackChunkName: "mention-tracking-index" */ '@/views/crm/mentionTracking/Index.vue'),
    meta: {
      title: "Mention Tracking",
      appBarTitle: "Mention Tracking",
      template: "dashboard",
      serviceRequired: constants.model.user.allowedServices.mentionTracking
    }
  },

  {
    path: '/mention-tracking/create',
    name: 'MentionTrackingCreate',
    component: () => import(/* webpackChunkName: "mention-tracking-create" */ '@/views/crm/mentionTracking/Create.vue'),
    meta: {
      title: "New Mention Tracking",
      appBarTitle: "New Mention Tracking",
      template: "dashboard",
      serviceRequired: constants.model.user.allowedServices.mentionTracking
    }
  },

  {
    path: '/mention-tracking/view/:uuid',
    name: 'MentionTrackingView',
    redirect: { name: "MentionTrackingReport" },
    component: () => import(/* webpackChunkName: "mention-tracking-view" */ '@/views/crm/mentionTracking/View.vue'),
    children: [
      {
        path: "report",
        name: "MentionTrackingReport",
        component: () => import(/* webpackChunkName: "mention-tracking-report" */ '@/views/crm/mentionTracking/Report.vue'),
        meta: {
          title: "Mention Tracking",
          appBarTitle: "Mention Tracking",
          template: "dashboard",
          serviceRequired: constants.model.user.allowedServices.mentionTracking,
          isGuestAllowed: true,
          switchTemplateToMinimalIfUnauthenticated: true
        },
      },
    ]
  },
]
