export default {
  // Query parameters used when making requests
  query: {
    search: "",
    perPage: 10,
    page: 1,
    sortBy: "id",
    sortOrder: "desc"
  },

  // An array of object
  filters: [],

  // The entire API response
  // Has things like, current_page, total, data etc.
  apiResponse: null,

  // The entire API response for credit usage
  creditUsageResponse: null
}
