export default {
  /**
   * App's .env variables object
   *
   * @param {Object} state
   * @returns {Object}
   */
  env(state) {
    return state.env
  },

  /**
   * For the responsiveness mixin
   *
   * @param {Object} state
   * @returns {Object}
   */
  responsiveness(state) {
    return state.responsiveness
  },

  /**
   * Get configuration value from a key
   *
   * @param {Object} state
   * @returns {Function}
   */
  get: (state) => (key) => {
    // Check if it exists
    const index = state.values.findIndex((item) => item.key === key)

    // If it exists, return it
    if (index !== -1) {
      return state.values[index].value
    }

    // Fallback to null
    return null
  }
}
