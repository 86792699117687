// Import helper functions
import { getClientID } from "@/helpers/clientHelper"

// Export the store actions
export default {
  /**
   * Update the selected platform's value
   *
   * @param {Object} context
   * @param {String} payload
   */
  updateSelectedPlatform({ commit }, payload) {
    commit("UPDATE_SELECTED_PLATFORM", payload)
  },

  /**
   * Update the query values in local state
   *
   * @param {Object} context
   * @param {Object} { key: String, value: String|Integer }
   */
  updateQuery({ commit }, { key, value }) {
    commit("UPDATE_QUERY", { key, value })
  },

  /**
   * Update the query values in local state for exports
   *
   * @param {Object} context
   * @param {Object} { key: String, value: String|Integer }
   */
  updateExportQuery({ commit }, { key, value }) {
    commit("UPDATE_EXPORT_QUERY", { key, value })
  },

  /**
   * Fetch unlocked insights from the API
   *
   * @param {Object} context
   */
  async fetchItems({ dispatch, commit, getters }) {
    // Create the loader
    const loaderId = Symbol("influencerInsights/actions/fetchItems")
    dispatch("loaders/add", loaderId, { root: true })

    try {
      // Compute query parameters
      const queryParams = new window.URLSearchParams(Object.entries(getters.query).filter(([key, value]) => value))

      // Make the network request
      const response = await axios({
        url: "/api/influencers/?" + queryParams
      })

      // Update the vuex state
      commit("UPDATE_RESPONSE", response.data)
    } catch (error) {
      // Log using the helper function
      logger({ type: "Network Error", error, comment: "Error while fetching reports" })

      // Show the error message
      dispatch("toasts/add", { text: "Error while fetching reports!" }, { root: true })
    } finally {
      // Close the loader
      dispatch("loaders/remove", loaderId, { root: true })
    }
  },

  /**
   * Fetch export history from the API
   *
   * @param {Object} context
   */
  async fetchExportItems({ dispatch, commit, getters }) {
    // Create the loader
    const loaderId = Symbol("influencerInsights/actions/fetchExportItems")
    dispatch("loaders/add", loaderId, { root: true })

    try {
      // Compute query parameters
      const queryParams = new window.URLSearchParams(Object.entries(getters.exportQuery).filter(([key, value]) => value))

      // Make the network request
      const response = await axios({
        url: "/api/influencer-insight-exports/?" + queryParams
      })

      // Update the vuex state
      commit("UPDATE_EXPORT_RESPONSE", response.data)
    } catch (error) {
      // Log using the helper function
      logger({ type: "Network Error", error, comment: "Error while fetching export history" })

      // Show the error message
      dispatch("toasts/add", { text: "Error while fetching export history!" }, { root: true })
    } finally {
      // Close the loader
      dispatch("loaders/remove", loaderId, { root: true })
    }
  },

  /**
   * Fetches the profile data if not available in local storage
   * Makes a network request to the API
   *
   * @param {Object} context
   * @param {Object} payload
   */
  async fetchProfile({ commit, getters, dispatch }, { platform, username, accountId, reportId = null, showError = false, getRecentReport = false }) {
    // If profile exists in local storage and we're not asking for an update, don't fetch it again
    if (getters.findProfile({ platform, username, reportId }) && getRecentReport === false) return

    // if it's some other profile, reset the state
    commit("RESET_PROFILES")

    // push it as an error
    const pushError = (data) => {
      // otherwise push the error
      commit("PUSH_PROFILE", {
        data,
        reportId,
        platform,
        username,
        accountId,
        isError: true
      })
    }

    // create a loader
    const loaderId = Symbol()
    dispatch("loaders/add", loaderId, { root: true })

    try {
      const queryParams = new window.URLSearchParams({
        reportId: reportId || "",
        accountId: accountId || "",
        getRecentReport: getRecentReport ? "true" : "false"
      })

      const response = await axios({
        url: `/api/influencers/${platform}/${username}/?${queryParams}`
      })

      // if the API didn't work, return and end it here
      if (!response.data.success) {
        pushError(response.data)
      }
      else {
        // otherwise push the results
        commit("PUSH_PROFILE", {
          platform,
          username,
          accountId,
          reportId,
          isError: false,
          data: response.data
        })
      }

      // refresh profile data with deducted balance
      window.dispatchEvent(new CustomEvent('fetchAuthUserProfile', {
        detail: {
          module: "influencerInsight",
          origin: "influencerInsight/fetchProfile"
        }
      }))
    } catch (error) {
      // log using the helper function
      logger({ type: "Network Error", error })

      if (error.response && error.response.data) {
        pushError(error.response.data)
      }

      if (showError) {
        dispatch("toasts/add", { text: error.response.error_message }, { root: true })
      }
    } finally {
      // close the loader
      dispatch("loaders/remove", loaderId, { root: true })
    }
  },

  /**
   * Make the POST request to generate PDF for the report file
   *
   * @param {Object} context
   * @param {Object} payload
   */
  async downloadReport({ dispatch }, { platform, username, accountId, reportId, triggerId, type = "pdf", shouldSendEmail = false }) {
    // Create a loader
    const loaderId = Symbol()
    dispatch("loaders/add", loaderId, { root: true })

    try {
      const queryParams = new window.URLSearchParams({
        accountId: accountId || "",
        type
      })

      await axios({
        url: `/api/influencers/${platform}/${username}/report?${queryParams}`,
        method: "POST",
        data: {
          // The origin for this request
          triggerId,
          // Pass the current tab's ID to make sure only we download the file once
          clientId: getClientID(),
          // The reportId incase we need to fetch a version
          reportId,
          // Also whether or not to notify the user about this download
          shouldSendEmail
        }
      })
    } catch (error) {
      // log using the helper function
      logger({ type: "Network Error", error })

      // Show a message
      dispatch("toasts/add", { text: "An error occurred, please try again" }, { root: true })

      // Also stop the loader from download button
      window.dispatchEvent(new CustomEvent("StopDownloadProgress", {
        detail: {
          origin: "influencerInsight/downloadReport",
          for: "InfluencerInsight",
          platform,
          accountId,
        }
      }))
    } finally {
      dispatch("loaders/remove", loaderId, { root: true })
    }
  },

  /**
   * Make PUT request to update the model
   *
   * @param {Object} context
   * @param {Object} payload
   */
  async updateExportItem({ dispatch }, { id, title }) {
    // Create the loader
    const loaderId = Symbol("influencerInsight/actions/updateExportItem")
    dispatch("loaders/add", loaderId, { root: true })

    try {
      // Make the network request
      await axios({
        method: "PUT",
        url: `/api/influencer-insight-exports/${id}`,
        data: { title }
      })

      // Refresh the list of audience overlaps
      dispatch("fetchExportItems")

      // Show the success message
      dispatch("toasts/add", { text: "Updated export!" }, { root: true })
    } catch (error) {
      // Log using the helper function
      logger({ type: "Network Error", error, comment: "Error while updating influencer insight export" })

      // Show the error message
      dispatch("toasts/add", { text: "Error while updating export!" }, { root: true })
    } finally {
      // Close the loader
      dispatch("loaders/remove", loaderId, { root: true })
    }
  },

  /**
   * Make DELETE request to delete the model
   *
   * @param {Object} context
   * @param {Object} payload
   */
  async deleteExportItem({ dispatch }, { id, showToast = true, fetchItems = true }) {
    // Create the loader
    const loader = Symbol("influencerInsight/actions/deleteExportItem")
    dispatch("loaders/add", loader, { root: true })

    try {
      // Make the network request
      await axios({
        method: "DELETE",
        url: `/api/influencer-insight-exports/${id}`
      })

      if (fetchItems) {
        // Refresh the list of audience overlaps
        dispatch("fetchExportItems")
      }

      if (showToast) {
        // Show the success message
        dispatch("toasts/add", { text: "Deleted influencer insight export!" }, { root: true })
      }
    } catch (error) {
      // log using the helper function
      logger({ type: "Network Error", error, comment: "Error while deleting the export" })

      // Show the error message
      dispatch("toasts/add", { text: "Error while deleting the export!" }, { root: true })
    } finally {
      // close the loader
      dispatch("loaders/remove", loader, { root: true })
    }
  },
}
