// Import the vue-router instance
import router from '@/router'

// Export the actions object
export default {
  /**
   * Fetch items and store them locally to show in the table
   *
   * @param {Object} context
   * @returns {void}
   */
  async fetchItems({ commit, dispatch }) {
    // Create the loader
    const loaderId = Symbol()
    dispatch("loaders/add", loaderId, { root: true })

    try {
      const response = await axios({
        url: "/api/influencer-outreach"
      })

      // otherwise push the results
      commit("UPDATE_ITEMS", response.data)
    } catch (error) {
      // log using the helper function
      logger({ type: "Network Error", error })
    } finally {
      // close the loader
      dispatch("loaders/remove", loaderId, { root: true })
    }
  },

  /**
   * Fetch the detailed object to be shown in the UI
   *
   * @param {Object} context
   * @param {String} uuid
   * @returns {void}
   */
  async fetchDetails({ commit, dispatch }, uuid) {
    // Create the loader
    const loaderId = Symbol()
    dispatch("loaders/add", loaderId, { root: true })

    try {
      const response = await axios({
        url: "/api/influencer-outreach/" + uuid
      })

      // Otherwise push the results
      commit("UPDATE_DETAILS", response.data)
    } catch (error) {
      // Log using the helper function
      logger({ type: "Network Error", error })

      // Redirect to 404 page
      router.replace("/404")
    } finally {
      // Close the loader
      dispatch("loaders/remove", loaderId, { root: true })
    }
  },

  /**
   * Update the local value for the campaign model
   *
   * @param {Object} context
   * @param {Object} payload
   * @returns {void}
   */
  updateCampaign({ commit }, payload) {
    commit("UPDATE_CAMPAIGN", payload)
  },

  /**
   * Update the local value for this influencer in store
   *
   * @param {Object} context
   * @param {Object} params
   * @returns {void}
   */
  updateInfluencer({ commit }, { id, data, campaignId }) {
    commit("UPDATE_INFLUENCER", { id, data, campaignId })
  },

  /**
   * Push a new note object in the store
   *
   * @param {Object} context
   * @param {Object} payload
   * @returns {void}
   */
  createNote({ commit }, payload) {
    commit("CREATE_NOTE", payload)
  },

  /**
   * Update the existing object in the store
   *
   * @param {Object} context
   * @param {Object} payload
   * @returns {void}
   */
  updateNote({ commit }, payload) {
    commit("UPDATE_NOTE", payload)
  },

  /**
   * Remove the existing object in the store
   *
   * @param {Object} context
   * @param {Object} payload
   * @returns {void}
   */
  deleteNote({ commit }, payload) {
    commit("DELETE_NOTE", payload)
  },

  /**
   * Update the influencer's prices attributes
   *
   * @param {Object} context
   * @param {Object} payload
   * @returns {void}
   */
  updatePrices({ commit }, { influencer, prices }) {
    commit("UPDATE_PRICES", { influencer, prices })
  },

  /**
   * Push a new task object in the store
   *
   * @param {Object} context
   * @param {Object} payload
   * @returns {void}
   */
  createTask({ commit }, payload) {
    commit("CREATE_TASK", payload)
  },

  /**
   * Update the existing object in the store
   *
   * @param {Object} context
   * @param {Object} payload
   * @returns {void}
   */
  updateTask({ commit }, payload) {
    commit("UPDATE_TASK", payload)
  },

  /**
   * Remove the existing object in the store
   *
   * @param {Object} context
   * @param {Object} payload
   * @returns {void}
   */
  deleteTask({ commit }, payload) {
    commit("DELETE_TASK", payload)
  },

  /**
   * Append the email to the array
   *
   * @param {Object} context
   * @param {Object} payload
   * @returns {void}
   */
  createEmail({ commit }, payload) {
    commit("CREATE_EMAIL", payload)
  },

  /**
   * Update the emails in the array
   *
   * @param {Object} context
   * @param {Object} payload
   * @returns {void}
   */
  updateEmails({ commit }, payload) {
    commit("UPDATE_EMAILS", payload)
  }
}
