
import constants from '@/helpers/constants.js'

export default [
  {
    path: '/social-sentiments',
    name: 'SocialSentimentsHistory',
    component: () => import(/* webpackChunkName: "social-sentiments-history" */ '@/views/socialSentiments/History.vue'),
    meta: {
      title: "Social Sentiments",
      appBarTitle: "Social Sentiments",
      template: "dashboard",
      serviceRequired: constants.model.user.allowedServices.socialSentiments
    }
  },
  {
    path: '/social-sentiments/report/:uuid',
    name: 'SocialSentimentsReport',
    component: () => import(/* webpackChunkName: "social-sentiments-report" */ '@/views/socialSentiments/Report.vue'),
    meta: {
      title: "Social Sentiments Report",
      appBarTitle: "Social Sentiments",
      template: "dashboard",
      serviceRequired: constants.model.user.allowedServices.socialSentiments
    }
  },
]
