// Export the state object
export default {
  // The default value for selected platforms
  selectedPlatforms: [],

  // Detailed Objects
  details: [],

  // Query parameters used when making requests
  query: {
    search: "",
    platforms: [],
    status: null,

    perPage: 10,
    page: 1,

    sortBy: "id",
    sortOrder: "desc"
  },

  // API response data
  response: {
    created_by_user: {
      data: [],
      total: 10,
    },

    created_by_team: {
      data: [],
      total: 10,
    },

    shared_with_user: {
      data: [],
      total: 10,
    }
  }
}
