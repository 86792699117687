export default {
  /**
   * Update the query values in local state
   *
   * @param {Object} context
   * @param {Object} { key: String, value: String|Integer }
   */
  updateQuery({ commit }, { key, value }) {
    commit("UPDATE_QUERY", { key, value })
  },

  /**
   * Update the local users array
   *
   * @param {Object} context
   */
  async fetchItems({ dispatch, commit, getters }) {
    // Create the loader
    const loaderId = Symbol()
    dispatch("loaders/add", loaderId, { root: true })

    // Prepare the filters
    const filters = {}

    // Check if lastUsed is set
    const filterLastActiveAt = getters.findFilterByType("lastActiveAt")
    if (filterLastActiveAt) {
      filters.last_active_at_from = filterLastActiveAt.data.inputs.from
      filters.last_active_at_to = filterLastActiveAt.data.inputs.to

      filters.last_active_at_inverted = filterLastActiveAt.data.inputs.inverted ? 1 : 0
    }

    // Check if paid status is set
    const filterIsDemo = getters.findFilterByType("isDemo")
    if (filterIsDemo) {
      filters.paid_status = filterIsDemo.data.inputs.value ? "demo" : "paid"
    }

    // Check if the is whitelisted filter is set
    const filterIsWhitelisted = getters.findFilterByType("isWhitelisted")
    if (filterIsWhitelisted) {
      filters.is_whitelisted = filterIsWhitelisted.data.inputs.value ? 1 : 0
    }

    // Check if the is disabled filter is set
    const filterIsDisabled = getters.findFilterByType("isDisabled")
    if (filterIsDisabled) {
      filters.is_disabled = filterIsDisabled.data.inputs.value ? 1 : 0
    }

    // Check if the is expired filter is set
    const filterIsExpired = getters.findFilterByType("isExpired")
    if (filterIsExpired) {
      filters.validity = filterIsExpired.data.inputs.value
    }

    // Check if the is inactive filter is set
    const filterIsInactive = getters.findFilterByType("isInactive")
    if (filterIsInactive) {
      filters.is_inactive = filterIsInactive.data.inputs.value ? 1 : 0
    }

    // Check if the can use API is set
    const filterCanUseApi = getters.findFilterByType("canUseAPI")
    if (filterCanUseApi) {
      filters.can_use_api = filterCanUseApi.data.inputs.value ? 1 : 0
    }

    // Check if the credit type is set
    const filterCreditType = getters.findFilterByType("creditType")
    if (filterCreditType) {
      filters.credit_type = filterCreditType.data.inputs.value
    }

    // Check if the country is set
    const filterCountry = getters.findFilterByType("country")
    if (filterCountry) {
      filters.country = filterCountry.data.inputs.value
    }

    // Check if the balance is set
    const filterBalance = getters.findFilterByType("balance")
    if (filterBalance) {
      filters.balance_condition = filterBalance.data.inputs.value
      filters.balance_amount = filterBalance.data.inputs.amount
    }

    // Check if to include team members in response
    const filterIncludeTeamMembers = getters.findFilterByType("includeTeamMembers")
    if (filterIncludeTeamMembers) {
      filters.include_team_members = filterIncludeTeamMembers.data.inputs.value ? 1 : 0
    }

    try {
      const queryParams = new window.URLSearchParams({
        ...getters.query,
        ...filters
      })

      const response = await axios({ url: "/api/admin/users?" + queryParams })

      // map and modify the data
      response.data.data = response.data.data.map((item) => {
        return {
          ...item,
          last_active_at: item.last_active_at
            ? dayjs.utc(item.last_active_at).tz(dayjs.tz.guess()).format("LLL")
            : "NA",
          last_active_at_relative: item.last_active_at ? dayjs().to(dayjs.utc(item.last_active_at)) : "NA"
        }
      })

      // update the vuex state
      commit("UPDATE_API_RESPONSE", response.data)
    } catch (error) {
      // log using the helper function
      logger({ type: "Network Error", error })
    } finally {
      // close the loader
      dispatch("loaders/remove", loaderId, { root: true })
    }
  },

  /**
   * Push an item to the filters array
   *
   * @param Object context
   * @param Object data
   */
  addFilter({ commit }, { type, data, sort = 10, isHidden = false, preventDelete = false }) {
    commit("ADD_FILTER_ITEM", {
      type,
      data,
      sort,
      isHidden,
      preventDelete,
      id: Symbol(),
      timestamp: Date.now()
    })

    // Trigger event to show the new filter
    window.dispatchEvent(
      new CustomEvent("addFilter", {
        detail: {
          module: "admin/users",
          origin: "admin/users/addFilter",
          item: arguments[1]
        }
      })
    )
  },

  /**
   * Find the item by the unique Symbol identifier and then delete it by splice method
   *
   * @param Object context
   * @param Object data
   */
  removeFilter({ commit, getters }, id) {
    const itemToDelete = getters.findFilterById(id)

    // If found, emit the window event
    if (itemToDelete) {
      // trigger event to let them know what was deleted
      window.dispatchEvent(
        new CustomEvent("removeFilter", {
          detail: {
            module: "admin/users",
            origin: "admin/users/removeFilter",
            item: itemToDelete
          }
        })
      )
    }

    commit("REMOVE_FILTER_ITEM", id)
  },

  /**
   * Finds and removes all applied filters of a type
   *
   * @param {Object} context
   * @param {Object} data
   */
  removeFilters({ dispatch, getters }, type) {
    const items = getters.findFiltersByType(type)

    for (const item of items) {
      dispatch("removeFilter", item.id)
    }
  },

  /**
   * Find the filter item, delete it and then push a new item
   *
   * @param Object context
   * @param Object data
   */
  async replaceFilter({ getters, dispatch }, { type, data, sort = 10, isHidden = false, preventDelete = false }) {
    // Find the item and remove it
    const itemToDelete = getters.findFiltersByType(type).find((search) => search.data.kind === data.kind)
    if (itemToDelete) {
      await dispatch("removeFilter", itemToDelete.id)
    }

    // Add a new item for that type instead
    await dispatch("addFilter", { type, data, sort, isHidden, preventDelete })
  },

  /**
   * Find the filter item, update it's values
   *
   * @param Object context
   * @param Object data
   */
  updateFilter({ commit }, { id, data }) {
    commit("UPDATE_FILTER_ITEM", { id, data })

    window.dispatchEvent(
      new CustomEvent("updateFilter", {
        detail: {
          module: "admin/users",
          origin: "admin/users/updateFilter",
          item: arguments[1]
        }
      })
    )
  },

  /**
   * Whenever platform is switched, reset the filters array
   *
   * @param Object context
   */
  resetFilters({ commit }) {
    // Find the item and remove it
    commit("RESET_FILTERS")

    window.dispatchEvent(
      new CustomEvent("resetFilters", {
        detail: {
          module: "admin/users",
          origin: "admin/users/resetFilters"
        }
      })
    )

    // Trigger event for fetching data
    window.dispatchEvent(
      new CustomEvent("triggerSearch", {
        detail: {
          module: "admin/users",
          origin: "admin/users/resetFilters"
        }
      })
    )
  }
}
