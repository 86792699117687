// Import host data
import host from '@/json/host.js'

// Define the route items
const items = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/auth/Login.vue'),
    meta: {
      title: "Login",
      template: "minimal",
      isGuestAllowed: true,
      isGuestRequired: true,
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "forgot" */ '@/views/auth/Forgot.vue'),
    meta: {
      title: "Forgot Password",
      template: "minimal",
      isGuestAllowed: true,
      isGuestRequired: true,
    }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "reset" */ '@/views/auth/Reset.vue'),
    meta: {
      title: "Reset Password",
      template: "minimal",
      isGuestAllowed: true,
      isGuestRequired: true,
    }
  },
]

// If the host allows registration, add the registration route
if (host.allowRegistration) {
  items.push({
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '@/views/auth/Register.vue'),
    meta: {
      title: "Create an Account",
      template: "minimal",
      isGuestAllowed: true,
      isGuestRequired: true,
      includeTrackingCode: true
    }
  })
}

// Return the route items
export default items
