// Export the mutations object
export default {
  /**
   * Pushes a new notification item to the array
   * @param {Object} state
   * @param {Object} payload
   * @returns {void}
   */
  ADD_ITEM(state, payload) {
    // Push the object in the store
    state.items.push({
      key: Symbol(),
      payload: {
        text: "A notification!",
        type: "info",
        icon: "lightbulb",

        // Replace the default values with this
        ...payload
      }
    })
  },

  /**
   * Removes an identifiable notification element from the array
   *
   * @param {Object} state
   * @param {Symbol} key
   */
  REMOVE_ITEM(state, key) {
    // Try to find the index
    const index = state.items.findIndex((search) => {
      return search.key === key
    })

    // If found
    if (index !== -1) {
      // Remove it
      state.items.splice(index, 1)
    }
  }
}