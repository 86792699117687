import dayjs from "dayjs"

import utc from "dayjs/plugin/utc"
import isToday from "dayjs/plugin/isToday"
import timezone from "dayjs/plugin/timezone"
import isBetween from "dayjs/plugin/isBetween"
import isYesterday from "dayjs/plugin/isYesterday"
import relativeTime from "dayjs/plugin/relativeTime"
import localizedFormat from "dayjs/plugin/localizedFormat"
import customParseFormat from "dayjs/plugin/customParseFormat"

dayjs.extend(utc)
dayjs.extend(isToday)
dayjs.extend(timezone)
dayjs.extend(isBetween)
dayjs.extend(isYesterday)
dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)
dayjs.extend(customParseFormat)

export default dayjs
