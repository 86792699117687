const CSV = require("csv")
const XLSX = require("xlsx")
import download from "downloadjs"

export default function downloadCSVtoXLSX({ data, filename }) {
  // convert the CSV string to object
  CSV.parse(data, {
    relax_column_count: true,
    skip_records_with_empty_values: true,
    skip_records_with_error: true
  },(error, result) => {
    // map the first header row to form an object
    const dataArray = []
    for (const row of result.slice(1)) {
      let index = 0

      const rowObject = {}

      for (const column of row) {
        rowObject[ result[0][index] ] = column

        index++
      }

      dataArray.push(rowObject)
    }

    if (!error) {
      let ws = XLSX.utils.json_to_sheet(dataArray)
      let wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, "sheet")

      download(XLSX.write(wb, {bookType:'xlsx', type:'binary'}), filename, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
    }
  })
}
