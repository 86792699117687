// Import helper objects
import constants from '@/helpers/constants.js'

// Export the route items
export default [
  {
    path: '/campaign-tracking',
    name: 'CampaignTrackingIndex',
    component: () => import(/* webpackChunkName: "campaign-tracking-index" */ '@/views/crm/campaignTracking/Index.vue'),
    meta: {
      title: "Campaign Tracking",
      appBarTitle: "Campaign Tracking",
      template: "dashboard",
      serviceRequired: constants.model.user.allowedServices.campaignTracking
    }
  },

  {
    path: '/campaign-tracking/create',
    name: 'CampaignTrackingCreate',
    component: () => import(/* webpackChunkName: "campaign-tracking-create" */ '@/views/crm/campaignTracking/Create.vue'),
    meta: {
      title: "New Campaign Tracking",
      appBarTitle: "New Campaign Tracking",
      template: "dashboard",
      serviceRequired: constants.model.user.allowedServices.campaignTracking
    }
  },

  {
    path: '/campaign-tracking/view/:uuid',
    name: 'CampaignTrackingView',
    redirect: { name: "CampaignTrackingInfluencers" },
    component: () => import(/* webpackChunkName: "campaign-tracking-view" */ '@/views/crm/campaignTracking/View.vue'),
    children: [
      {
        path: "influencers",
        name: "CampaignTrackingInfluencers",
        component: () => import(/* webpackChunkName: "campaign-tracking-view-influencers" */ '@/views/crm/campaignTracking/Influencers.vue'),
        meta: {
          title: "Campaign Tracking",
          appBarTitle: "Campaign Tracking",
          template: "dashboard",
          serviceRequired: constants.model.user.allowedServices.campaignTracking,
          isGuestAllowed: true,
          switchTemplateToMinimalIfUnauthenticated: true
        },
      },
      {
        path: "edit",
        name: "CampaignTrackingEdit",
        component: () => import(/* webpackChunkName: "campaign-tracking-edit" */ '@/views/crm/campaignTracking/Edit.vue'),
        meta: {
          title: "Edit Campaign Tracking",
          appBarTitle: "Edit Campaign Tracking",
          template: "dashboard",
          serviceRequired: constants.model.user.allowedServices.campaignTracking,
          isGuestAllowed: true,
          switchTemplateToMinimalIfUnauthenticated: true
        },
      },
      {
        path: "posts",
        name: "CampaignTrackingPosts",
        component: () => import(/* webpackChunkName: "campaign-tracking-posts" */ '@/views/crm/campaignTracking/Posts.vue'),
        meta: {
          title: "Campaign Tracking",
          appBarTitle: "Campaign Tracking",
          template: "dashboard",
          serviceRequired: constants.model.user.allowedServices.campaignTracking,
          isGuestAllowed: true,
          switchTemplateToMinimalIfUnauthenticated: true
        },
      },
      {
        path: "stories",
        name: "CampaignTrackingStories",
        component: () => import(/* webpackChunkName: "campaign-tracking-stories" */ '@/views/crm/campaignTracking/Stories.vue'),
        meta: {
          title: "Campaign Tracking",
          appBarTitle: "Campaign Tracking",
          template: "dashboard",
          serviceRequired: constants.model.user.allowedServices.campaignTracking,
          isGuestAllowed: true,
          switchTemplateToMinimalIfUnauthenticated: true
        },
      },
      {
        path: "analytics",
        name: "CampaignTrackingAnalytics",
        component: () => import(/* webpackChunkName: "campaign-tracking-analytics" */ '@/views/crm/campaignTracking/Analytics.vue'),
        meta: {
          title: "Campaign Tracking",
          appBarTitle: "Campaign Tracking",
          template: "dashboard",
          serviceRequired: constants.model.user.allowedServices.campaignTracking,
          isGuestAllowed: true,
          switchTemplateToMinimalIfUnauthenticated: true
        },
      },
    ]
  },
]
