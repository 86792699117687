// Export the mutations object
export default {
  /**
   * Update the query parameter values
   *
   * @param {Object} state
   * @param {Object} { key: String, value: String|Integer }
   */
  UPDATE_QUERY(state, { key, value }) {
    state.query[key] = value
  },

  /**
   * Update the API response
   *
   * @param {Object} state
   * @param {Object} payload
   */
  UPDATE_RESPONSE(state, payload) {
    state.response.created_by_user = payload.created_by_user || state.response.created_by_user
    state.response.created_by_team = payload.created_by_team || state.response.created_by_team
    state.response.shared_with_user = payload.shared_with_user || state.response.shared_with_user
    state.response.public_reports = payload.public_reports || state.response.public_reports
  },

  /**
   * Update the value of the detailed object in the local store
   *
   * @param {Object} state
   * @param {Object} payload
   * @returns {void}
   */
  UPDATE_DETAILS(state, payload) {
    // Find if an object for it already exists
    const index = state.details.findIndex((search) => search.model.uuid === payload.model.uuid)

    // If it exists
    if (index !== -1) {
      // Remove it
      state.details.splice(index, 1)
    }

    // Push the object into the store
    state.details.push(payload)
  },

  /**
   * Replace the model value in local store
   *
   * @param {Object} state
   * @param {Object} payload
   * @returns {void}
   */
  UPDATE_MODEL(state, payload) {
    // Find if the object exists
    const detail = state.details.find((search) => search.model.id === payload.id)

    // If it exists
    if (detail) {
      // Update the model value for it
      detail.model = payload
    }
  },

  /**
   * Add or update the campaign member to the list
   *
   * @param {Object} state
   * @param {Object} payload
   * @returns {void}
   */
  UPDATE_MEMBER(state, payload) {
    // Find the detailed object
    const detail = state.details.find((search) => search.model.id === payload.id)

    // If found the details object
    if (detail) {
      // Check if member exists
      const memberIndex = detail.members.findIndex((search) => search.id === payload.member.id)

      // If found
      if (memberIndex !== -1) {
        // Replace it
        detail.members.splice(memberIndex, 1, payload.member)
      }
      // Otherwise, push a new value
      else {
        detail.members.push(payload.member)
      }
    }
  },

  /**
   * Remove the campaign member from the list
   *
   * @param {Object} state
   * @param {Object} payload
   * @returns {void}
   */
  REMOVE_MEMBER(state, payload) {
    // Find the detailed object
    const detail = state.details.find((search) => search.model.id === payload.id)

    // If found the details object
    if (detail) {
      // Check if member exists
      const memberIndex = detail.members.findIndex((search) => search.id === payload.member.id)

      // If found
      if (memberIndex !== -1) {
        // Remove it
        detail.members.splice(memberIndex, 1)
      }
    }
  },

  /**
   * Update the value of selected platform
   *
   * @param {Object} state
   * @param {String} payload
   */
  UPDATE_SELECTED_PLATFORM(state, payload) {
    // Find if the object exists
    const item = state.selectedPlatforms.find((search) => search.id === payload.id)

    // If it exists, update its value
    if (item) {
      item.value = payload.value
    }
    // Otherwise, push a new value
    else {
      state.selectedPlatforms.push({
        id: payload.id,
        value: payload.value
      })
    }
  },
}
