import _get from "lodash/get"

// define a function that fetches the value from store
export const getValue = ({ type, path = "value", defaultValue = "" }, findFilterByType) => {
  const filter = findFilterByType(type)

  // if the filter is not found, return the default value
  if (!filter) return defaultValue

  // otherwise use lodash to get the value from inputs object
  const value = _get(filter.data.inputs, path, defaultValue)

  // in cases where the upper limit is infinity, we should send an empty string
  return value === Infinity.toString() ? defaultValue : value
}

// kind could either be influencer or audience
export const getValueByKind = ({ type, kind, path = "value", defaultValue = "" }, findFilterByTypeAndKind) => {
  const filter = findFilterByTypeAndKind(type, kind)

  // if the filter is not found, return the default value
  if (!filter) return defaultValue

  // otherwise use lodash to get the value from inputs object
  return _get(filter.data.inputs, path, defaultValue)
}
