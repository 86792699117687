// Force remove all the registered service workers
if (window.navigator && window.navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    for (const registration of registrations) {
      registration.unregister()
    }
  })
}

// If the app is in production mode
if (process.env.VUE_APP_IS_PRODUCTION === "true") {
  // Import firebase configuration
  const { initializeApp } = require("firebase/app")
  const { getAnalytics } = require("firebase/analytics")

  // Initialize Firebase
  const firebaseApp = initializeApp({
    apiKey: "AIzaSyClJ1g2m4F5yrOUaFm0-lZTYrceR2OIqAQ",
    authDomain: "klug-8e85f.firebaseapp.com",
    projectId: "klug-8e85f",
    storageBucket: "klug-8e85f.appspot.com",
    messagingSenderId: "654729262327",
    appId: "1:654729262327:web:c4b22ef754691ab9648ff2",
    measurementId: "G-R9ZC96BH3B"
  })

  window._FIREBASE_ANALYTICS = getAnalytics(firebaseApp)
}

// Import node packages
import Vue from 'vue'
import Vuelidate from 'vuelidate'
import { useFavicon } from '@vueuse/core'

// Import the default stylesheet
import './css/index.css'
import './stylus/index.styl'

// Import the vue app container, router and store
import App from './App.vue'
import router from './router'
// import router from './router/maintenance'
import store from './store'

// Import vuetify configuration
import vuetify from './plugins/vuetify'

// Make all helpers global functions
import host from './json/host'
import echo from './helpers/echo'
import wait from './helpers/wait'
import isURL from './helpers/isURL'
import dayjs from './helpers/dayjs'
import * as dates from './helpers/dates'
import axios from './helpers/axios'
import regex from './helpers/regex'
import logger from './helpers/logger'
import colors from './helpers/colors'
import flagEmoji from './helpers/flagEmoji'
import * as numeral from './helpers/numeral'
import nFormatter from './helpers/nFormatter'
import fetchProfile from './helpers/fetchProfile'
import redirectToWeb from './helpers/redirectToWeb'
import getTextFromHTML from './helpers/getTextFromHTML'
import platformIconMap from './helpers/platformIconMap'
import titleCaseString from './helpers/titleCaseString'
import capitalizeString from './helpers/capitalizeString'
import downloadCSVtoXLSX from "./helpers/downloadCSVtoXLSX"
import generateRandomString from './helpers/generateRandomString'
import { localStorageManager, getAccessToken } from './helpers/localStorageManager'

// Using a single source for all constant related values to make sure no future spelling error occurs
import constants from "@/helpers/constants.js"

// Import the mixins
import global from './mixins/global'
import responsiveness from './mixins/responsiveness'

// Register them in window
window.host = host
window.echo = echo
window.wait = wait
window.isURL = isURL
window.dayjs = dayjs
window.dates = dates
window.axios = axios
window.regex = regex
window.logger = logger
window.colors = colors
window.numeral = numeral
window.flagEmoji = flagEmoji
window.nFormatter = nFormatter
window.fetchProfile = fetchProfile
window.redirectToWeb = redirectToWeb
window.getAccessToken = getAccessToken
window.platformIconMap = platformIconMap
window.getTextFromHTML = getTextFromHTML
window.titleCaseString = titleCaseString
window.capitalizeString = capitalizeString
window.downloadCSVtoXLSX = downloadCSVtoXLSX
window.localStorageManager = localStorageManager
window.generateRandomString = generateRandomString

window.constants = constants

// as well as global mixin for templates
Vue.mixin({
  data: () => ({
    echo,
    constants,
    platformIconMap,
    colorsHelper: colors,
  }),

  methods: {
    isURL,
    dayjs,
    axios,
    logger,
    flagEmoji,
    nFormatter,
    redirectToWeb,
    getAccessToken,
    getTextFromHTML,
    titleCaseString,
    capitalizeString,
    downloadCSVtoXLSX,
    localStorageManager,
    generateRandomString,

    // individually register numeral's methods
    ...numeral,
    ...dates,
  }
})

// Register the mixins
Vue.mixin(global)
Vue.mixin(responsiveness)

// Configure the global vue object
Vue.config.productionTip = false
Vue.use(Vuelidate)

// The async function to make sure we fetch data before we load the app
const handleApp = async () => {
  // Fetch the profileData if we can
  if (window.profileData === undefined) {
    // Make the network request
    await fetchProfile()

    // Store the data in global window object
    window.profileData = store.getters["auth/profile"]
  }

  // Once done, render the Vue app
  const vueInstance = new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')

  window.addEventListener('beforeunload', () => {
    vueInstance.$destroy()
  })
}

// Run the function
handleApp()

// Update the browser tab's favicon
useFavicon(host.images.favicon)
