// For storing access_token and expiry dates
export const API_DATA = "API_DATA"

// To store all the client IDs in an array
export const CLIENT_DATA = "CLIENT_DATA"

// To store admin related data
export const ADMIN_DATA = "ADMIN_DATA"

// For storing UI related user settings
export const SETTINGS_DATA = "SETTINGS_DATA"

/**
 * Sets and gets localStorage data in a structured way such that it is easier to be mapped in "watch" of composition API
 * @param { name, key: <String, Nullable>, value: <Any, Nullable>, children: <Array, Nullable> }
 * @returns { Object } | LocalStorage Item
 */

export function localStorageManager({ name, key = null, value = null, children = [] }, deleteStore = false) {
  // prefix localStorage item name to namespace it
  const itemName = `${(window.host.name || '').toUpperCase()}_${name}`

  if (deleteStore) {
    localStorage.removeItem(itemName)

    return true
  }

  // fetch the item
  const _LS = localStorage.getItem(itemName)
  let LS = {}

  if (_LS === "null" || _LS === null || _LS === "") {
    LS = {}
  } else {
    try {
      // check if the item returned from localStorage is either null or an object
      LS = JSON.parse(_LS ? _LS : "{}")
    } catch (e) {
      // means that neither the localStorage Item is null or an json object
      localStorage.setItem(itemName, "{}")
    }
  }

  // set a single key value in the item
  if (key && value) {
    LS[key] = value

    localStorage.setItem(itemName, JSON.stringify(LS))

    return LS
  }
  // loop through all of the object in array and set key and values in the item
  else if (children.length) {
    children.forEach(({ key, value }) => {
      LS[key] = value
    })

    localStorage.setItem(itemName, JSON.stringify(LS))

    return LS
  }
  // if no key and values to be set, simply return the fetched localStorage item
  else {
    return LS
  }
}

/**
 * Checks whether access token exists or not in localStorage
 *
 * @return {String}
 */
export function getAccessToken() {
  return localStorageManager({ name: API_DATA })?.access_token || null
}
