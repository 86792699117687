
import constants from '@/helpers/constants.js'

export default [
  {
    path: '/audience-overlap',
    name: 'AudienceOverlapHistory',
    component: () => import(/* webpackChunkName: "audience-overlap-history" */ '@/views/audienceOverlap/History.vue'),
    meta: {
      title: "Audience Overlap",
      appBarTitle: "Audience Overlap",
      template: "dashboard",
      serviceRequired: constants.model.user.allowedServices.audienceOverlap
    }
  },
  {
    path: '/audience-overlap/report/:uuid',
    name: 'AudienceOverlapReport',
    component: () => import(/* webpackChunkName: "audience-overlap-report" */ '@/views/audienceOverlap/Report.vue'),
    meta: {
      title: "Audience Overlap Report",
      appBarTitle: "Audience Overlap",
      template: "dashboard",
      serviceRequired: constants.model.user.allowedServices.audienceOverlap
    }
  },
]
