export default {
  /**
   * Return the selected platform's name
   *
   * @param {Object} state
   * @returns {String}
   */
  selectedPlatform(state) {
    return state.selectedPlatform
  },

  /**
   * Return the query object used when making requests
   *
   * @param {Object} state
   * @returns {Object}
   */
  query(state) {
    return state.query
  },

  /**
   * Return the response object
   *
   * @param {Object} state
   * @returns {Object}
   */
  response(state) {
    return state.response
  },

  /**
   * Return the query object used when making requests for exports
   *
   * @param {Object} state
   * @returns {Object}
   */
  exportQuery(state) {
    return state.exportQuery
  },

  /**
   * Return the response object for exports
   *
   * @param {Object} state
   * @returns {Object}
   */
  exportResponse(state) {
    return state.exportResponse
  },

  /**
   * Return all the profiles in local cache/state storage
   *
   * @param {Object} state
   * @returns {Array}
   */
  allProfiles(state) {
    return state.profiles
  },

  /**
   * Find the profile that's stored by matching platform and username in the local storage
   *
   * @param {Object} state
   * @returns {Object}
   */
  findProfile: (state) => ({ platform, username, accountId, reportId = null }) => {
    return state.profiles.find((search) => {
      const condition1 = search.platform === platform
      const condition2 = [search.username, search.accountId].includes(username) || [search.username, search.accountId].includes(accountId)
      const condition3 = reportId ? String(search.reportId) === String(reportId) : true

      // Return the profile if all conditions are met
      return condition1 && condition2 && condition3
    })
  }
}
