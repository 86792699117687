export default {
  // Query parameters used when making requests
  query: {
    search: "",

    platform: null,
    type: null,
    status: null,
    createdBy: null,

    perPage: 10,
    page: 1,

    sortBy: "id",
    sortOrder: "desc"
  },

  // API response data
  response: {
    data: [],
    total: 10,
  }
}
