// Define the global key to store data in
const storeKey = "_MESSAGE_EVENT_SUBSCRIBERS"

/**
 * Create an empty subscribers store in global scope
 *
 * @returns {void}
 */
function initiate() {
  window[storeKey] = []
}

/**
 * Get a registered subscription if available
 *
 * @param {Symbol} id
 * @returns {Object|Null}
 */
function getSubscriptionById(id) {
  return window[storeKey].find((search) => search.id === id)
}

/**
 * Register the subscription in global store
 *
 * @param {Object} params
 * @returns {void}
 */
function register({ id, module, type, key, callback, validator = null }) {
  // Check if we already have this registered
  if (getSubscriptionById(id)) {
    // Stop further execution
    return
  }

  // Otherwise, save this as a new object
  window[storeKey].push({
    id,
    module,
    type,
    key,
    callback,
    validator
  })
}

/**
 * Deregister a subscriber from global scope
 *
 * @param {Symbol} id
 * @returns {void}
 */
function deregister(id) {
  // Find the index
  const index = window[storeKey].findIndex((search) => search.id === id)

  // If found
  if (index !== -1) {
    // Delete it
    window[storeKey].splice(index, 1)
  }
}

/**
 * Handle the incoming data and check if we have any listeners attached for it
 *
 * @param {Object} eventData
 * @returns {void}
 */
function fireListeners(eventData) {
  // Go through each of the subscribed listener
  for (const subscriber of window[storeKey]) {

    // If we have a custom validator for this
    if (subscriber.validator) {
      // If it didn't pass the validator
      if (!subscriber.validator(eventData)) {
        // Skip it
        continue
      }
    }

    // Otherwise, run custom validator
    else if (
      subscriber.module !== eventData.module ||
      subscriber.type !== eventData.type ||
      subscriber.key !== eventData.key
    ) {
      // If any of the condition fails, skip it
      continue
    }

    // Otherwise, if all validations are successful
    if (subscriber.callback && typeof subscriber.callback === "function") {
      // Call the function with input data
      subscriber.callback(eventData)
    }
  }
}

// Export the functions
export default {
  storeKey,
  initiate,
  register,
  deregister,
  fireListeners,
  getSubscriptionById,
}