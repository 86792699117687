// Import helper objects
import constants from '@/helpers/constants.js'

// Export the route items
export default [
  {
    path: '/influencer-outreach',
    name: 'InfluencerOutreachIndex',
    component: () => import(/* webpackChunkName: "influencer-outreach-index" */ '@/views/crm/influencerOutreach/Index.vue'),
    meta: {
      title: "Influencer Outreach",
      appBarTitle: "Influencer Outreach",
      template: "dashboard",
      serviceRequired: constants.model.user.allowedServices.influencerOutreach
    }
  },

  {
    path: '/influencer-outreach/create',
    name: 'InfluencerOutreachCreate',
    component: () => import(/* webpackChunkName: "influencer-outreach-create" */ '@/views/crm/influencerOutreach/Create.vue'),
    meta: {
      title: "New Influencer Outreach",
      appBarTitle: "New Influencer Outreach",
      template: "dashboard",
      serviceRequired: constants.model.user.allowedServices.influencerOutreach
    }
  },

  {
    path: '/influencer-outreach/view/:uuid',
    name: 'InfluencerOutreachView',
    component: () => import(/* webpackChunkName: "influencer-outreach-view" */ '@/views/crm/influencerOutreach/View.vue'),
    children: [
      {
        path: "detail",
        name: "InfluencerOutreachDetail",
        component: () => import(/* webpackChunkName: "influencer-outreach-view-detail" */ '@/views/crm/influencerOutreach/Detail.vue'),
        meta: {
          title: "Influencer Outreach",
          appBarTitle: "Influencer Outreach",
          template: "dashboard",
          serviceRequired: constants.model.user.allowedServices.influencerOutreach
        },
      },
      {
        path: "edit",
        name: "InfluencerOutreachEdit",
        component: () => import(/* webpackChunkName: "influencer-outreach-edit" */ '@/views/crm/influencerOutreach/Edit.vue'),
        meta: {
          title: "Edit Outreach",
          appBarTitle: "Edit Outreach",
          template: "dashboard",
          serviceRequired: constants.model.user.allowedServices.influencerOutreach
        },
      },
      {
        path: "analytics",
        name: "InfluencerOutreachAnalytics",
        component: () => import(/* webpackChunkName: "influencer-outreach-analytics" */ '@/views/crm/influencerOutreach/analytics/All.vue'),
        meta: {
          title: "Influencer Outreach",
          appBarTitle: "Influencer Outreach",
          template: "dashboard",
          serviceRequired: constants.model.user.allowedServices.influencerOutreach
        },
      },
      {
        path: "influencers/:influencerId",
        name: "InfluencerOutreachInfluencer",
        component: () => import(/* webpackChunkName: "influencer-outreach-view-influencer" */ '@/views/crm/influencerOutreach/Influencer.vue'),
        meta: {
          title: "View Influencer",
          appBarTitle: "View Influencer",
          template: "dashboard",
          serviceRequired: constants.model.user.allowedServices.influencerOutreach
        },
      },
      {
        path: "influencers/:influencerId/analytics",
        name: "InfluencerOutreachInfluencerAnalytics",
        meta: {
          component: () => import(/* webpackChunkName: "influencer-outreach-influencer-analytics" */ '@/views/crm/influencerOutreach/analytics/Individual.vue'),
          title: "Influencer Outreach Report",
          appBarTitle: "Influencer Outreach Report",
          template: "dashboard",
          serviceRequired: constants.model.user.allowedServices.influencerOutreach
        },
      },
    ]
  },
]
