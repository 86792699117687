// Import helper objects
import constants from '@/helpers/constants.js'

// Export the route items
export default [
  {
    path: "/google-trends",
    name: "GoogleTrendsHistory",
    component: () => import(/* webpackChunkName: "google-trends-history" */ "@/views/googleTrends/History.vue"),
    meta: {
      template: "dashboard",
      title: "Google Trends",
      appBarTitle: "Google Trends",
      serviceRequired: constants.model.user.allowedServices.googleTrends
    }
  },
  {
    path: "/google-trends/report/:uuid",
    name: "GoogleTrendsReport",
    component: () => import(/* webpackChunkName: "profile-report" */ "@/views/googleTrends/Report.vue"),
    meta: {
      template: "dashboard",
      title: "Profile Report",
      appBarTitle: "Profile Report",
      serviceRequired: constants.model.user.allowedServices.googleTrends
    }
  },
]
