// Export the getters object
export default {
  /**
   * Return the query
   *
   * @param {Object} state
   * @returns {Object}
   */
  query(state) {
    return state.query
  },

  /**
   * Return the response
   *
   * @param {Object} state
   * @returns {Object}
   */
  response(state) {
    return state.response
  },
}
