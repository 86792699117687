// Import helper objects
import constants from '@/helpers/constants.js'

// Export the route items
export default [
  // {
  //   path: "/influencer-insight",
  //   name: "InfluencerInsight",
  //   component: () => import(/* webpackChunkName: "influencer-insight" */ "@/views/influencerInsight/Index.vue"),
  //   meta: {
  //     template: "dashboard",
  //     title: "Influencer Insight",
  //     appBarTitle: "Influencer Insight",
  //     serviceRequired: constants.model.user.allowedServices.influencerInsight
  //   }
  // },
  {
    path: "/influencer-insight",
    name: "InfluencerInsight",
    component: () => import(/* webpackChunkName: "influencer-insight-history" */ "@/views/influencerInsight/History.vue"),
    meta: {
      template: "dashboard",
      title: "Influencer Insight",
      appBarTitle: "",
      serviceRequired: constants.model.user.allowedServices.influencerInsight
    }
  },
  {
    path: "/influencer-insight/report/:platform/:username",
    name: "InfluencerInsightReport",
    component: () => import(/* webpackChunkName: "profile-report" */ "@/views/influencerInsight/Report.vue"),
    meta: {
      template: "dashboard",
      title: "Profile Report",
      appBarTitle: "Profile Report",
      serviceRequired: constants.model.user.allowedServices.influencerInsight
    }
  },
  {
    path: "/influencer-insight-tie-breaker",
    name: "InfluencerTieBreaker",
    component: () => import(/* webpackChunkName: "influencer-tie-breaker" */ "@/views/influencerInsight/TieBreaker.vue"),
    meta: {
      template: "dashboard",
      title: "Influencer Tie Breaker",
      appBarTitle: "Influencer Tie Breaker",
      serviceRequired: constants.model.user.allowedServices.influencerTieBreaker
    }
  },
  {
    path: "/influencer-insight/export",
    name: "InfluencerInsightsExport",
    component: () => import(/* webpackChunkName: "influencer-insight-export" */ "@/views/influencerInsight/Export.vue"),
    meta: {
      template: "dashboard",
      title: "Influencer Insights Export",
      appBarTitle: "Influencer Insights Export",
      serviceRequired: {
        type: "or",
        items: [
          constants.model.user.allowedServices.actionBulkExportInfluencerInsightsPDF,
          constants.model.user.allowedServices.actionBulkExportInfluencerInsightsXLSX
        ]
      }
    }
  },
  {
    path: "/influencer-insight/export/history",
    name: "InfluencerInsightsExportHistory",
    component: () => import(/* webpackChunkName: "influencer-insight-export-history" */ "@/views/influencerInsight/ExportHistory.vue"),
    meta: {
      template: "dashboard",
      title: "Influencer Insights Export History",
      appBarTitle: "Influencer Insights Export History",
      serviceRequired: {
        type: "or",
        items: [
          constants.model.user.allowedServices.actionBulkExportInfluencerInsightsPDF,
          constants.model.user.allowedServices.actionBulkExportInfluencerInsightsXLSX
        ]
      }
    }
  },
]
