// Export the getters object
export default {
  /**
   * Return the query
   *
   * @param {Object} state
   * @returns {Object}
   */
  query(state) {
    return state.query
  },

  /**
   * Return the response
   *
   * @param {Object} state
   * @returns {Object}
   */
  response(state) {
    return state.response
  },

  /**
   * Return the detailed items
   *
   * @param {Object} state
   * @returns {Array}
   */
  details(state) {
    return state.details
  },

  /**
   * Get the detailed object by uuid
   *
   * @param {Object} state
   * @param {String} uuid
   * @returns {Object}
   */
  detailByUuid: (state) => (uuid) => {
    return state.details.find((search) => search.model.uuid === uuid)
  },

  /**
   * Return the analytics items
   *
   * @param {Object} state
   * @returns {Array}
   */
  analytics(state) {
    return state.analytics
  },

  /**
   * Get the analytics object by id
   *
   * @param {Object} state
   * @returns {Object}
   */
  analyticsById: (state) => (id) => {
    return state.analytics.find((search) => search.id === id) || null
  },

  /**
   * Return the selected platform
   *
   * @param {Object} state
   * @returns {String}
   */
  selectedPlatformById: (state) => (id) => {
    return state.selectedPlatforms.find((search) => search.id === id)?.value || null
  },

  /**
   * Return the selected influencer
   *
   * @param {Object} state
   * @returns {Object}
   */
  selectedInfluencerById: (state) => (id) => {
    return state.selectedInfluencers.find((search) => search.id === id)?.value || null
  },

  /**
   * Return the influencer's query object by id
   *
   * @param {Object} state
   * @returns {Object}
   */
  influencersQueryById: (state) => (id) => {
    return state.result.influencers.find((search) => search.id === id)?.query || null
  },

  /**
   * Return the influencer's response object by id
   *
   * @param {Object} state
   * @returns {Object}
   */
  influencersResponseById: (state) => (id) => {
    return state.result.influencers.find((search) => search.id === id)?.response || null
  },

  /**
   * Return the posts grid's query object by id
   *
   * @param {Object} state
   * @returns {Object}
   */
  postsGridQueryById: (state) => (id) => {
    return state.result.posts.grid.find((search) => search.id === id)?.query || null
  },

  /**
   * Return the posts grid's response object by id
   *
   * @param {Object} state
   * @returns {Object}
   */
  postsGridResponseById: (state) => (id) => {
    return state.result.posts.grid.find((search) => search.id === id)?.response || null
  },

  /**
   * Return the posts table's query object by id
   *
   * @param {Object} state
   * @returns {Object}
   */
  postsTableQueryById: (state) => (id) => {
    return state.result.posts.table.find((search) => search.id === id)?.query || null
  },

  /**
   * Return the posts table's response object by id
   *
   * @param {Object} state
   * @returns {Object}
   */
  postsTableResponseById: (state) => (id) => {
    return state.result.posts.table.find((search) => search.id === id)?.response || null
  },

  /**
   * Return the stories grid's query object by id
   *
   * @param {Object} state
   * @returns {Object}
   */
  storiesGridQueryById: (state) => (id) => {
    return state.result.stories.grid.find((search) => search.id === id)?.query || null
  },

  /**
   * Return the stories grid's response object by id
   *
   * @param {Object} state
   * @returns {Object}
   */
  storiesGridResponseById: (state) => (id) => {
    return state.result.stories.grid.find((search) => search.id === id)?.response || null
  },

  /**
   * Return the stories table's query object by id
   *
   * @param {Object} state
   * @returns {Object}
   */
  storiesTableQueryById: (state) => (id) => {
    return state.result.stories.table.find((search) => search.id === id)?.query || null
  },

  /**
   * Return the stories table's response object by id
   *
   * @param {Object} state
   * @returns {Object}
   */
  storiesTableResponseById: (state) => (id) => {
    return state.result.stories.table.find((search) => search.id === id)?.response || null
  }
}
