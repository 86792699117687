export default {
  /**
   * Return the query object used when making requests
   *
   * @param {Object} state
   * @returns {Object}
   */
  query(state) {
    return state.query
  },

  /**
   * Return the API response for users endpoint
   *
   * @param {Object} state
   * @returns {Object|Null}
   */
  apiResponse(state) {
    return state.apiResponse
  },

  /**
   * Return the API response for credit usage endpoint
   *
   * @param {Object} state
   * @returns {Object|Null}
   */
  creditUsageResponse(state) {
    return state.creditUsageResponse
  }
}
