export default {
  // Defaults to instagram
  selectedPlatform: "instagram",

  // Query parameters used when making requests
  query: {
    search: "",
    platform: null,

    perPage: 10,
    page: 1,

    sortBy: "id",
    sortOrder: "desc"
  },

  response: {
    data: [],
    total: 10,
  },

  // Query parameters used when making requests for exports
  exportQuery: {
    search: "",
    status: null,
    type: null,
    platforms: [],

    perPage: 10,
    page: 1,

    sortBy: "id",
    sortOrder: "desc"
  },

  exportResponse: {
    data: [],
    total: 10,
  },

  // Stores profiles in a manner of objects
  // { username, platform, data }
  profiles: []
}
