export default {
  /**
   * Pushes a new toast item to the array
   * @param {Object} state
   * @param {Object} payload : Should have keys like text, isError, action, button: { class, text }, timeout
   */
  ADD_ITEM(state, payload) {
    // Maximum toasts at one time shouldn't exceed 3
    if (state.items.length > 2) {
      // Remove everything but leave last 2 toasts
      const toasts = state.items.reverse()
      toasts.splice(2)

      // Assign this to state
      state.items = toasts.reverse()
    }

    // Setting symbol key and timeout first, so that it can be overwritten easily
    state.items.push({ key: Symbol(), timeout: 3000, ...payload })
  },

  /**
   * Removes an identifiable toast element from the array
   * @param {Object} state
   * @param {Symbol} toastKey
   */
  REMOVE_ITEM(state, toastKey) {
    const index = state.items.findIndex((toast) => {
      return toast.key === toastKey
    })

    if (index !== -1) {
      state.items.splice(index, 1)
    }
  }
}