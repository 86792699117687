export default {
  /**
   * Update the query parameter values
   *
   * @param {Object} state
   * @param {Object} { key: String, value: String|Integer }
   */
  UPDATE_QUERY(state, { key, value }) {
    state.query[key] = value
  },

  /**
   * Update the api results in local state
   *
   * @param {Object} state
   * @param {Object} payload
   */
  UPDATE_API_RESPONSE(state, payload) {
    state.apiResponse = payload
  },

  /**
   * Update the API results in local state
   *
   * @param {Object} state
   * @param {Object} payload
   */
  UPDATE_CREDIT_USAGE_RESPONSE(state, payload) {
    state.creditUsageResponse = payload
  }
}
