// Import Vuetify Library
import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import { VMenu, VDialog } from 'vuetify/lib'

// Import lodash merge
import merge from 'lodash/merge'

// Import the host configuration object
import host from '@/json/host'
import { hexToRgb, lighten, darken } from '@/helpers/colors'

// Get the theme type
const themeType = host.theme.type || "light"

// Import Vuetify CSS file
Vue.use(Vuetify,{
  components: {
    VMenu,
    VDialog
  }
})

// Create a new Instance of Vuetify Object
const configuration = {
  icons: {
    // Change the icon font to "material-design"
    iconfont: 'md'
  },

  // update the color palette to match existing app
  theme: {
    dark: themeType === "dark",

    options: {
      customProperties: true,
    },

    themes: {}
  }
}

// Set the default values for the theme
configuration.theme.themes[themeType] = {
  primary: {
    base: "#f05d56",
    lighten1: "#f48a85",
    lighten2: "#f6a5a1",
    darken1: "#ba4842",
    darken2: "#6a2926"
  },
  secondary: {
    base: "#373b56",
    darken1: "#232638",
    darken2: "#08080c"
  },
  accent: {
    base: "#f4f6f8"
  },
}

// Use lodash to overwrite the theme colors
configuration.theme.themes[themeType] = merge(configuration.theme.themes[themeType], host.theme.color || {})

// Set the navigation color value if it doesn't exist
if (!configuration.theme.themes[themeType].navigation) {
  configuration.theme.themes[themeType].navigation = configuration.theme.themes[themeType].secondary
}

// Set the navigation text color value if it doesn't exist
if (!configuration.theme.themes[themeType].navigationText) {
  configuration.theme.themes[themeType].navigationText = "#FFFFFF"
}

// Set the primary button color value if it doesn't exist
if (!configuration.theme.themes[themeType].buttonPrimary) {
  configuration.theme.themes[themeType].buttonPrimary = configuration.theme.themes[themeType].primary
}

// Set the primary button text color value if it doesn't exist
if (!configuration.theme.themes[themeType].buttonPrimaryText) {
  configuration.theme.themes[themeType].buttonPrimaryText = "#FFFFFF"
}

// Set the secondary button color value if it doesn't exist
if (!configuration.theme.themes[themeType].buttonSecondary) {
  configuration.theme.themes[themeType].buttonSecondary = configuration.theme.themes[themeType].secondary
}

// Set the secondary button text color value if it doesn't exist
if (!configuration.theme.themes[themeType].buttonSecondaryText) {
  configuration.theme.themes[themeType].buttonSecondaryText = "#FFFFFF"
}

// Set the text color value if it doesn't exist
if (!configuration.theme.themes[themeType].text) {
  configuration.theme.themes[themeType].text = "#000000"
}

// Set the primary text color value if it doesn't exist
if (!configuration.theme.themes[themeType].primaryText) {
  configuration.theme.themes[themeType].primaryText = "#FFFFFF"
}

// Set the primary text color value if it doesn't exist
if (!configuration.theme.themes[themeType].secondaryText) {
  configuration.theme.themes[themeType].secondaryText = "#000000"
}

// Create a new instance of Vuetify
const vuetify = new Vuetify(configuration)

// Go through each color value
for (const [name, value] of Object.entries(configuration.theme.themes[themeType])) {
  // Get the base value
  const base = typeof value === "string" ? value : value.base

  // Set the CSS variable
  document.documentElement.style.setProperty(`--v-${name}`, base)
  document.documentElement.style.setProperty(`--v-${name}-rgb`, hexToRgb(base))

  // For 1 to 4 levels, set dark values
  for (let i = 1; i <= 4; i++) {
    // Set the CSS variable
    document.documentElement.style.setProperty(`--v-${name}-darken-${i}`, value[`darken${i}`] || darken(base, i * 15))

    // Set the RGB variable
    document.documentElement.style.setProperty(`--v-${name}-darken-${i}-rgb`, hexToRgb(value[`darken${i}`] || darken(base, i * 15)))
  }

  // For 1 to 5 levels, set light values
  for (let i = 1; i <= 5; i++) {
    // Set the CSS variable
    document.documentElement.style.setProperty(`--v-${name}-lighten-${i}`, value[`lighten${i}`] || lighten(base, i * 15))

    // Set the RGB variable
    document.documentElement.style.setProperty(`--v-${name}-lighten-${i}-rgb`, hexToRgb(value[`lighten${i}`] || lighten(base, i * 15)))
  }
}

// Return the Vuetify Object
export default vuetify
