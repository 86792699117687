export default {
  /**
   * Return the query object used when making requests
   *
   * @param {Object} state
   * @returns {Object}
   */
  query(state) {
    return state.query
  },

  /**
   * Return the response object
   *
   * @param {Object} state
   * @returns {Object}
   */
  response(state) {
    return state.response
  }
}
