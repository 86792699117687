export default [
  {
    path: '/admin',
    name: 'Admin/Index',
    component: () => import(/* webpackChunkName: "admin-index" */ '@/views/admin/Index.vue'),
    meta: {
      title: "Admin Control Center",
      appBarTitle: "Admin Control Center",
      // template: "admin"
      template: "dashboard",
      roleRequired: ["admin"]
    }
  },

  // FOR #USERS SECTION
  {
    path: '/admin/users',
    name: 'Admin/Users/Index',
    component: () => import(/* webpackChunkName: "admin-users-index" */ '@/views/admin/users/Index.vue'),
    meta: {
      title: "View Users",
      appBarTitle: "User Manager",
      // template: "admin",
      template: "dashboard",
      roleRequired: ["admin"],
      breadcrumbs: [
        // First Step is the Admin Panel
        {
          text: "Admin",
          to: { name: "Admin/Index" }
        },
        {
          text: "User Manager",
          to: { name: "Admin/Index" }
        },
        "View User"
      ]
    }
  },

  {
    path: '/admin/users/create',
    name: 'Admin/Users/Create',
    component: () => import(/* webpackChunkName: "admin-users-create" */ '@/views/admin/users/Create.vue'),
    meta: {
      title: "Add User",
      appBarTitle: "User Manager",
      // template: "admin",
      template: "dashboard",
      roleRequired: ["admin"],
      breadcrumbs: [
        // First Step is the Admin Panel
        {
          text: "Admin",
          to: { name: "Admin/Index" }
        },
        // Second and third step is just text
        {
          text: "User Manager",
          to: { name: "Admin/Index" }
        },
        "Add User"
      ]
    }
  },

  {
    path: '/admin/users/edit/:userId',
    name: 'Admin/Users/Edit',
    component: () => import(/* webpackChunkName: "admin-users-edit" */ '@/views/admin/users/Edit.vue'),
    meta: {
      title: "Edit User",
      appBarTitle: "User Manager",
      // template: "admin",
      template: "dashboard",
      roleRequired: ["admin"],
      breadcrumbs: [
        // First Step is the Admin Panel
        {
          text: "Admin",
          to: { name: "Admin/Index" }
        },
        // Second and third step is just text
        {
          text: "User Manager",
          to: { name: "Admin/Index" }
        },
        {
          text: "View User",
          to: { name: "Admin/Users/Index" }
        },
        "Edit"
      ]
    }
  },

  {
    path: '/admin/users/rates',
    name: 'Admin/Users/Rates',
    component: () => import(/* webpackChunkName: "admin-users-rates" */ '@/views/admin/users/Rates.vue'),
    meta: {
      title: "User Prices",
      appBarTitle: "User Manager",
      // template: "admin",
      template: "dashboard",
      roleRequired: ["admin"],
      breadcrumbs: [
        // First Step is the Admin Panel
        {
          text: "Admin",
          to: { name: "Admin/Index" }
        },
        // Second and third step is just text
        {
          text: "User Manager",
          to: { name: "Admin/Index" }
        },
        "User Price"
      ]
    },
  },

  {
    path: '/admin/users/credit-usage',
    name: 'Admin/Users/Usage',
    component: () => import(/* webpackChunkName: "admin-users-usage" */ '@/views/admin/users/Usage.vue'),
    meta: {
      title: "Credit Usage",
      appBarTitle: "User Manager",
      // template: "admin",
      template: "dashboard",
      roleRequired: ["admin"],
      breadcrumbs: [
        // First Step is the Admin Panel
        {
          text: "Admin",
          to: { name: "Admin/Index" }
        },
        // Second and third step is just text
        {
          text: "User Manager",
          to: { name: "Admin/Index" }
        },
        "Credit Usage"
      ]
    }
  },

  {
    path: '/admin/users/credit-usage/:userId',
    name: 'Admin/Users/UsageByMonth',
    component: () => import(/* webpackChunkName: "admin-users-usage-by-month" */ '@/views/admin/users/UsageByMonth.vue'),
    meta: {
      title: "Credit Usage By Month",
      appBarTitle: "User Manager",
      // template: "admin",
      template: "dashboard",
      roleRequired: ["admin"],
      breadcrumbs: [
        // First Step is the Admin Panel
        {
          text: "Admin",
          to: { name: "Admin/Index" }
        },
        // Second and third step is just text
        {
          text: "User Manager",
          to: { name: "Admin/Index" }
        },
        {
          text: "Credit Usage",
          to: { name: "Admin/Users/Usage" }
        },
        "Month Wise"
      ]
    }
  },

  // FOR #CONTACTS SECTION
  {
    path: '/admin/contacts',
    name: 'Admin/Contacts/Index',
    component: () => import(/* webpackChunkName: "admin-contacts-index" */ '@/views/admin/contacts/Index.vue'),
    meta: {
      title: "View Contact Messages",
      appBarTitle: "Contact Messages",
      // template: "admin",
      template: "dashboard",
      roleRequired: ["admin"],
      breadcrumbs: [
        // First Step is the Admin Panel
        {
          text: "Admin",
          to: { name: "Admin/Index" }
        },
        "Contacts Manager",
        "Received Messages"
      ]
    }
  },

  {
    path: '/admin/contacts/notify',
    name: 'Admin/Contacts/Notify',
    component: () => import(/* webpackChunkName: "admin-contacts-notify" */ '@/views/admin/contacts/Notify.vue'),
    meta: {
      title: "Send Message",
      appBarTitle: "Send Message",
      // template: "admin",
      template: "dashboard",
      roleRequired: ["admin"],
      breadcrumbs: [
        // First Step is the Admin Panel
        {
          text: "Admin",
          to: { name: "Admin/Index" }
        },
        "Contacts Manager",
        "Send Message"
      ]
    }
  },

  // FOR #INFLUENCERS SECTION
  {
    path: '/admin/influencers/form',
    name: 'Admin/Influencers/Form',
    component: () => import(/* webpackChunkName: "admin-influencers-form" */ '@/views/admin/influencers/Form.vue'),
    meta: {
      title: "Add / Edit Data",
      appBarTitle: "Influencer Manager",
      // template: "admin",
      template: "dashboard",
      roleRequired: ["admin"],
      breadcrumbs: [
        // First Step is the Admin Panel
        {
          text: "Admin",
          to: { name: "Admin/Index" }
        },
        {
          text: "Influencer Data Manager",
          to: { name: "Admin/Index" }
        },
        "Add/Edit Data"
      ]
    }
  },

  {
    path: '/admin/influencers/kpi',
    name: 'Admin/Influencers/KPI',
    component: () => import(/* webpackChunkName: "admin-influencers-kpi" */ '@/views/admin/influencers/KPI.vue'),
    meta: {
      title: "Manage KPI",
      appBarTitle: "Influencer Manager",
      // template: "admin",
      template: "dashboard",
      roleRequired: ["admin"],
      breadcrumbs: [
        // First Step is the Admin Panel
        {
          text: "Admin",
          to: { name: "Admin/Index" }
        },
        {
          text: "Influencer Data Manager",
          to: { name: "Admin/Index" }
        },
        "KPI Management"
      ]
    }
  },

  {
    path: '/admin/influencers/rates',
    name: 'Admin/Influencers/Rates',
    component: () => import(/* webpackChunkName: "admin-influencers-rates" */ '@/views/admin/influencers/Rates.vue'),
    meta: {
      title: "Manage Influencer's Pricing",
      appBarTitle: "Influencer Manager",
      // template: "admin",
      template: "dashboard",
      roleRequired: ["admin"],
      breadcrumbs: [
        // First Step is the Admin Panel
        {
          text: "Admin",
          to: { name: "Admin/Index" }
        },
        {
          text: "Influencer Data Manager",
          to: { name: "Admin/Index" }
        },
        "Manage Influencer Pricing"
      ]
    }
  },
]
